import { FC } from "react";

import Toolbar from "@mui/material/Toolbar";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  numSelected: number;
  rowCount: number;
  onDelete: Function;
};

const AssignPolicyTableHeader: FC<Props> = ({
  numSelected,
  rowCount,
  onDelete,
}: Props) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 && (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      )}

      {numSelected > 0 && (
          <Button
          variant="contained"
          color="warning"
          className="btn-default"
          startIcon={<DeleteIcon/> }
            style={{ width: "200px", height: "40px" }}
            disableRipple
            onClick={() => onDelete()}
          >
           Delete {rowCount === numSelected ? 'all' : 'selected'}
          </Button>
      )}
    </Toolbar>
  );
};

export default AssignPolicyTableHeader;
