import { FC, Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import dayjs from "dayjs";

import UploadHistoryFiltering from "./UploadHistoryFiltering/UploadHistoryFiltering";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import ReportModal from "./ReportModal/ReportModal";
import PaginationPolicy from "../../components/PaginationPolicy";
import TableLoading from "../../components/TableLoading";
import ErrorNotifications from "../../components/ErrorNotifications/ErrorNotifications";

import { downloadFile, templateType, uploadHistoryQueryParams } from "../../util/helpers";

import { archiveHistoryEntryAPI, getHistoryFileAPI, getUploadHistoryAPI } from "../../api/fileUploadAPI";

import './UploadHistory.scss';

const UploadHistory: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [currentDeletionItemId, setCurrentDeletionItemId] = useState<number | undefined>(undefined);
    const [currentReport, setCurrentReport] = useState<number | undefined>(undefined);

    const [showPagingDuringLoading, setShowPagingDuringLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>();
    const [deleteLoading, setDeleteLoading] = useState<any>();
    const [deleteSnackBarError, setDeleteSnackBarError] = useState<any>();
    const [searchParams, setSearchParams] = useSearchParams();

    const fetchData = (searchParams: any) => {
        setData(undefined);
        setLoading(true);
        getUploadHistoryAPI(uploadHistoryQueryParams(searchParams)).then((res: any) => {
            setData(res?.items);
            setTotalPages(res?.totalPages);
        })
            .catch((err: any) => { setError(err); })
            .finally(() => {
                setLoading(false);
                setShowPagingDuringLoading(false);
            });
    };

    useEffect(() => {
        fetchData(searchParams);
    }, [searchParams]);

    return (<div className="upload-history-wrapper">
        <UploadHistoryFiltering />

        <TableContainer style={{ marginTop: '30px' }}>
            <Table sx={{ minWidth: 400 }} size="small">
                <TableHead>
                    <TableRow>

                        <TableCell>Original file name</TableCell>
                        <TableCell align="right">Template type</TableCell>
                        <TableCell align="right">Username</TableCell>
                        <TableCell align="right">Upload date</TableCell>
                        <TableCell align="right">Validation status</TableCell>
                        <TableCell align="right">File status</TableCell>
                        <TableCell align="right">Log status</TableCell>
                        <TableCell align="right">Report</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.map((item: any) => (<Fragment key={item?.id}>
                        <TableRow>
                            <TableCell>
                                <Link onClick={() => {
                                    getHistoryFileAPI(item.id).then((blob) => downloadFile(
                                        blob, item?.file?.name
                                    ));
                                }}>
                                    {item?.file?.name?.substring(0, 30) + '...'}
                                </Link>

                            </TableCell>
                            <TableCell align="right">{templateType(item?.templateType)}</TableCell>
                            <TableCell align="right">{item?.userName}</TableCell>
                            <TableCell align="right">{dayjs(item?.createdUtcDateTime).format('YYYY-MM-DD HH:mm')}</TableCell>
                            <TableCell align="right">{item?.validated ? 'Success' : 'Fail'}</TableCell>
                            <TableCell align="right">{item?.submitted ? 'Submitted' : 'Not Submitted'}</TableCell>
                            <TableCell align="right">{item?.archived ? 'Archived' : 'Active'}</TableCell>
                            <TableCell align="right"><Button onClick={() => setCurrentReport(item)} disableRipple className="btn-default">Show</Button></TableCell>
                            <TableCell align="right"><IconButton onClick={() => setCurrentDeletionItemId(item.id)} aria-label="delete">
                                <DeleteIcon />
                            </IconButton></TableCell>

                        </TableRow>
                    </Fragment>))}
                </TableBody>
            </Table>

            {loading && <TableLoading />}
            {error && <Typography component="div" style={{
                textAlign: 'center',
                marginTop: '40px',
                marginBottom: '40px'
            }} variant="h6">
                Something went wrong!
            </Typography>}

            {data && data?.length === 0 && <Typography component="div" style={{
                textAlign: 'center',
                marginTop: '40px',
                marginBottom: '40px'
            }} variant="h6">
                Upload history is empty!
            </Typography>}
        </TableContainer>

        {(!error && (showPagingDuringLoading || (!!totalPages && !loading))) && <PaginationPolicy totalPages={totalPages} onChange={() => setShowPagingDuringLoading(true)} />}



        <Dialog
            PaperProps={{
                style: {
                    borderRadius: '24px',
                    padding: '30px',
                    gap: '14px',
                }
            }}
            open={!!currentDeletionItemId}>
            <DialogTitle className="lato center bold">Delete log item?</DialogTitle>
            {/* <DialogContent>
                <DialogContentText className="lato center">
                    Delete log item?
                </DialogContentText>
            </DialogContent> */}
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button style={{ width: '120px', height: '37px' }} disableRipple className="btn-default-outlined" onClick={() => { setCurrentDeletionItemId(undefined) }}>No</Button>
                <LoadingButton style={{ width: '120px' }}
                    disableRipple className="btn-default"
                    loading={deleteLoading}
                    onClick={() => {
                        setDeleteLoading(true);
                        archiveHistoryEntryAPI(currentDeletionItemId).then(() => {
                            setShowPagingDuringLoading(true);
                            if (data?.length === 1) {
                                if (searchParams.get('page')) {
                                    if (searchParams.get('page') === '2') {
                                        setSearchParams((searchParams) => {
                                            searchParams.delete('page');
                                            return searchParams;
                                        });
                                    } else {
                                        if (Number(searchParams.get('page')) === totalPages) {
                                            setSearchParams((searchParams) => {
                                                searchParams.set('page', String(Number(searchParams.get('page')) - 1));
                                                return searchParams;
                                            });
                                        } else {
                                            fetchData(searchParams);
                                        }
                                    }
                                } else {
                                    fetchData(searchParams);
                                }

                            } else {
                                fetchData(searchParams);
                            }
                            //setData((oldData: any) => oldData?.filter((item: any) => item?.id !== currentPolicyId));
                            setCurrentDeletionItemId(undefined);
                            enqueueSnackbar('Log item is deleted!', {
                                variant: "success",
                                autoHideDuration: 3000,
                                anchorOrigin: { vertical: "top", horizontal: "center" }
                            });
                        }).catch((err: any) => {
                            setDeleteSnackBarError(err);
                            // setDeleteSnackBarMessage('Something went wrong!');
                        }).finally(() => {
                            setDeleteLoading(false);
                        });

                    }}>Yes</LoadingButton>
            </DialogActions>
        </Dialog>

        <ReportModal onClose={() => setCurrentReport(undefined)} report={currentReport} />

        <ErrorNotifications error={deleteSnackBarError} />
    </div>);
}

export default UploadHistory;