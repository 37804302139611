import { FC, useMemo, useRef, useState } from "react";
import { useSnackbar } from "notistack";

import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { LoadingButton } from "@mui/lab";

import { validateFileAPI } from "../../../api/fileUploadAPI";
import { generateErrorMessageFromResponse } from "../../../util/helpers";

import './UploadingBlock.scss';


type Props = {
    templateId: string;
    setValidateFile: Function;
    requestLoading: boolean;
    setRequestLoading: Function;
};

const UploadingBlock: FC<Props> = ({ templateId, setValidateFile, setRequestLoading, requestLoading }: Props) => {
    const [file, setFile] = useState(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleChangeFile = (e: any) => {
        const [currentFile] = e.target.files;
        if (currentFile) {
            setFile(currentFile);
        }
    }


    const fileName = useMemo(() => {
        if (!file) return '';
        const { name: fileName = '' } = file;
        if (fileName.length <= 25) return fileName;
        return `${fileName.slice(0, 25)}...`;
    }, [file]);

    return <Card style={{ marginTop: '10px' }} className="card without-border"
        variant="outlined">
        <div className="file-upload-subitem-wrapper">
            <h3 className="file-upload-subtitle">Upload your file here</h3>

            <input
                type="file"
                ref={inputRef}
                accept=".xls, .xlsx"
                onChange={handleChangeFile}
                style={{ display: 'none' }}
            />

            <div className="upload-block">
                <TextField
                    sx={{ width: 300 }}
                    className="input"
                    value={fileName}
                    onClick={() => inputRef?.current?.click()}
                    size="small"
                    InputProps={{
                        disableUnderline: true, endAdornment: (
                            <InputAdornment position="end">
                                <AttachFileOutlinedIcon />
                            </InputAdornment>
                        )
                    }}
                />

                <LoadingButton loading={requestLoading} disabled={!file || requestLoading}
                    onClick={() => {
                        setRequestLoading(true);
                        validateFileAPI(templateId, file).then((res) => {
                            setValidateFile(res);
                        })
                            .catch((err) => {
                                enqueueSnackbar(generateErrorMessageFromResponse(err), {
                                    variant: "error",
                                    autoHideDuration: 3000,
                                    anchorOrigin: { vertical: "top", horizontal: "center" }
                                });
                            }).finally(() => {
                                setRequestLoading(false);
                            });
                    }}
                    disableRipple className="btn-default">
                    Upload for validation
                </LoadingButton>
            </div>
        </div>
    </Card>
};

export default UploadingBlock;