import * as React from 'react';
import { useEffect, useState, useRef } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { getAssignitionUsersAPI } from '../../api/filtersAPI';
import { UserSelectAssignPolicyDTO } from '../../models/UserSelectAssignPolicyDTO';
import CircularProgress from '@mui/material/CircularProgress';


type Props = {
    value: any;
    setValue: Function;
}

const UserSelectAssignPolicy: React.FC<Props> = ({ value, setValue }: Props) => {
    const [inputValue, setInputValue] = useState('');
    const inputValueRef = useRef(inputValue);
    const [options, setOptions] = useState<Array<UserSelectAssignPolicyDTO>>([]);
    const [loadingList, setLoadingList] = useState(false);


    useEffect(() => {
        let interval: any = null;
        if (inputValueRef.current) {
            interval = setTimeout(() => {
                setLoadingList(true);
                setOptions([]);
                getAssignitionUsersAPI({ startOfEmail: inputValueRef.current }).then((res: Array<UserSelectAssignPolicyDTO>) => {
                    setOptions(res);
                }).finally(() => {
                    setLoadingList(false);
                });
            }, 300);
        } else {
            setOptions([]);
        }


        return () => {
            if (interval) {
                clearTimeout(interval);
            }
        }
    }, [inputValue]);

    return (
        <Autocomplete
            id="user-selector"
            size="small"
            sx={{ width: 250 }}
            getOptionLabel={(option: any) =>
                typeof option === 'string' ? option : option.email
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No Users"
            onOpen={() => {
                setOptions([]);
            }}
            onChange={(event: any, newValue: any) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                setInputValue('');
                inputValueRef.current = '';
            }}
            onBlur={() => {
                inputValueRef.current = '';
            }}
            loading={loadingList}
            onInput={(event: any) => {
                setInputValue(event.target.value);
                inputValueRef.current = event.target.value;
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    className="input"
                    variant="filled"
                    size="small"
                    InputProps={{
                        disableUnderline: true, ...params.InputProps, endAdornment: (
                            <React.Fragment>
                                {loadingList ? <CircularProgress color="inherit" style={{ marginTop: '-16px' }} size={16} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    label="User Id (email)" />
            )}
            renderOption={(props, option: UserSelectAssignPolicyDTO) => {
                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <Typography className="user-info" style={{ color: '#000', cursor: 'pointer' }}
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"

                                    variant="h6" component="div">
                                    <div className="user-round">
                                        {option.firstName ? option.firstName[0] : ''}{option.lastName ? option.lastName[0] : ''}

                                    </div>
                                </Typography>

                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <Box
                                    component="span"
                                    sx={{ fontWeight: 'regular', fontSize: '13px' }}
                                >
                                    {option.displayName}
                                </Box>

                                <Typography variant="body2" color="text.secondary"
                                    sx={{ fontWeight: 'regular', fontSize: '13px' }}

                                >
                                    {option.email}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}

export default UserSelectAssignPolicy;