import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";

import './PaginationPolicy.scss';

type Props = {
    totalPages: number;
    onChange: Function;
};

const PaginationPolicy: FC<Props> = ({ totalPages, onChange }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <Pagination className="pagination-default" count={totalPages} page={Number(searchParams.get('page')) || 1} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            if (value === 1) {
                setSearchParams((searchParams) => {
                    searchParams.delete('page');
                    return searchParams;
                });
            } else {
                setSearchParams((searchParams) => {
                    searchParams.set('page', String(value));
                    return searchParams;
                });
            }
            onChange();
        }} color="secondary" />
    )
};

export default PaginationPolicy;