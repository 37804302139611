import { acquireToken } from "../config/authConfig";


export default async function request(url: string,
    params: { method: string }, body?: any, action?: undefined | 'download'): Promise<any> {

    const token = await (() => {
        return acquireToken();
    })();

    const data: { body?: any } = {};
    if (body) {
        data.body = JSON.stringify(body);
    }

    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
            ...params,
            ...data,

            headers: {
                'Content-Type': 'application/json',
                ...(token?.accessToken ? { 'Authorization': `Bearer ${token?.accessToken}` } : {})
            }
        })
            .then((response) => {
                if (action === 'download') {
                    response.blob().then((blob) => {
                        if (response.ok) {
                            resolve(blob);
                        } else {
                            reject(blob);
                        }
                    })
                } else {
                    response.text().then((body) => {
                        if (response.ok) {
                            resolve(toJSON(body));
                        } else {
                            reject(toJSON(body));
                        }
                    })
                }

            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function uploadFileAPI(url: string, method: string, file: any) {
    const token = await (() => {
        return acquireToken();
    })();

    let formData = new FormData();
    formData.append('file', file);
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
            method,
            body: formData,
            headers: {
                ...(token?.accessToken ? { 'Authorization': `Bearer ${token?.accessToken}` } : {})
            }
        })
            .then((response) => {
                response.text().then((body) => {
                    if (response.ok) {
                        resolve(toJSON(body));
                    } else {
                        reject(toJSON(body));
                    }
                })
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function toJSON(data: any) {
    try {
        return JSON.parse(data);
    } catch (err) {
        return {
            errors: [
                {
                    type: 'IS_NOT_JSON_RESPONSE',
                    message: 'Response type is not JSON',
                }
            ]
        };
    }
}