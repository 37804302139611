import { FC, useState, useEffect, Fragment } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import Checkbox from "@mui/material/Checkbox";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import TableLoading from "../../../components/TableLoading";
import ErrorNotifications from "../../../components/ErrorNotifications";

import TableFilterPolicy from "../../../components/TableFilterPolicy";
import OrderingPolicy from "../../../components/OrderingPolicy";
import PaginationPolicy from "../../../components/PaginationPolicy";

import { getCurrentApplicationDataAccessPoliciesAPI } from "../../../api/applicationsAPI";
import { deleteDapPolicyAPI, exportDapFileAPI } from "../../../api/policiesAPI";
import {
  downloadFile,
  formatDate,
  policiesQueryParams,
} from "../../../util/helpers";

import "./DataAccessPolicies.scss";

const DataAccessPolicies: FC = () => {
  const [currentPolicyId, setCurrentPolicyId] = useState<any>();
  const [currentPolicyName, setCurrentPolicyName] = useState("");

  const [showPagingDuringLoading, setShowPagingDuringLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const [deleteLoading, setDeleteLoading] = useState<any>();
  const [deleteSnackBarError, setDeleteSnackBarError] = useState<any>();
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedArray, setSelectedArray] = useState<Array<any>>([]);

  const navigate = useNavigate();
  let { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = (id: any, searchParams: any) => {
    if (id) {
      setData(undefined);
      setLoading(true);
      getCurrentApplicationDataAccessPoliciesAPI(
        id,
        policiesQueryParams(searchParams)
      )
        .then((res: any) => {
          setData(res?.policies);
          setTotalPages(res?.totalPages);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
          setShowPagingDuringLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData(id, searchParams);
    setSelectedArray([]);
  }, [id, searchParams]);

  const getUserId = () => {
    const user =
      searchParams.get("user") !== null ? String(searchParams.get("user")) : "";
    if (user.length) {
      const [id] = user.split("||");
      return id;
    }
    return null;
  };

  const exportAction = () => {
    setExportLoading(true);

    let requestData: any = { applicationId: Number(id) };

    if (selectedArray.length) {
      requestData = {
        ...requestData,
        policyIds: selectedArray,
        policyName: null,
        userId: null,
      };
    } else {
      requestData = {
        ...requestData,
        policyName: searchParams.get("policyName"),
        userId: getUserId() ? Number(getUserId()) : null,
        policyIds: null,
      };
    }

    exportDapFileAPI(requestData)
      .then((blob) =>
        downloadFile(blob, `Policy_export_${formatDate(new Date())}.xlsx`)
      )
      .catch((err) => {
        setDeleteSnackBarError(err);
      })
      .finally(() => {
        setExportLoading(false);
      });
  };

  const deletePolicy = () => {
    setDeleteLoading(true);
    deleteDapPolicyAPI(currentPolicyId)
      .then(() => {
        setShowPagingDuringLoading(true);
        if (data?.length === 1) {
          if (searchParams.get("page")) {
            if (searchParams.get("page") === "2") {
              setSearchParams((searchParams) => {
                searchParams.delete("page");
                return searchParams;
              });
            } else {
              if (Number(searchParams.get("page")) === totalPages) {
                setSearchParams((searchParams) => {
                  searchParams.set(
                    "page",
                    String(Number(searchParams.get("page")) - 1)
                  );
                  return searchParams;
                });
              } else {
                fetchData(id, searchParams);
              }
            }
          } else {
            fetchData(id, searchParams);
          }
        } else {
          fetchData(id, searchParams);
        }
        //setData((oldData: any) => oldData?.filter((item: any) => item?.id !== currentPolicyId));
        setCurrentPolicyId(undefined);
        enqueueSnackbar(
          `Data Access Policy “${currentPolicyName}“ has been deleted successfully`,
          {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      })
      .catch((err) => {
        setDeleteSnackBarError(err);
        // setDeleteSnackBarMessage('Something went wrong!');
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <h2 className="table-title">Data Access Policies</h2>
        <div style={{ flex: "1 1 auto" }}></div>
        <LoadingButton
          loading={exportLoading}
          style={{ marginRight: "10px", height: "41px", width: "210px" }}
          onClick={exportAction}
          disableRipple
          className="btn-default"
          startIcon={<FilePresentIcon />}
        >
          {selectedArray.length
            ? "Export Selected Policies"
            : getUserId() || searchParams.get("policyName")
            ? "Export Filtered Policies"
            : "Export All Policies"}
        </LoadingButton>

        <Button
          onClick={() => navigate(`/application/dap/add-policy/${id}`)}
          className="btn-default-outlined"
          disableRipple
        >
          Add Policy
        </Button>
      </div>

      <TableFilterPolicy
        inputPolicyLabel="Policy Name"
        inputPolicyName="policyName"
        showUserFilter
      />

      <TableContainer className="data-access-policies-table-container">
        <Table sx={{ minWidth: 800 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  color="primary"
                  indeterminate={
                    selectedArray.length > 0 &&
                    selectedArray.length < data?.length
                  }
                  checked={
                    data?.length > 0 && selectedArray.length === data?.length
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      setSelectedArray([...data.map((d: any) => d.id)]);
                      return;
                    }
                    setSelectedArray([]);
                  }}
                />
              </TableCell>

              <TableCell>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "none!important",
                  }}
                >
                  Policy Name{" "}
                  <OrderingPolicy
                    noAction={data && data?.length === 0}
                    onChange={() => setShowPagingDuringLoading(true)}
                  />
                </div>
              </TableCell>
              <TableCell align="right">Filter Entity</TableCell>
              <TableCell align="right">Filter Attribute</TableCell>
              <TableCell align="right">Filter Value</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item: any) => {
                const isItemSelected = !!selectedArray.find(
                  (c) => c === item.id
                );

                return (
                  <Fragment key={item?.id}>
                    {item?.filters?.length ? (
                      item?.filters.map((filter: any, index: number) => (
                        <TableRow
                          selected={!!selectedArray.find((c) => c === item.id)}
                          sx={{ cursor: "pointer" }}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          onClick={() => {
                            const selectedIndex = selectedArray.findIndex(
                              (c) => c === item.id
                            );
                            let newSelected: any = [];

                            if (selectedIndex === -1) {
                              newSelected = newSelected.concat(
                                selectedArray,
                                item.id
                              );
                            } else if (selectedIndex === 0) {
                              newSelected = newSelected.concat(
                                selectedArray.slice(1)
                              );
                            } else if (
                              selectedIndex ===
                              selectedArray.length - 1
                            ) {
                              newSelected = newSelected.concat(
                                selectedArray.slice(0, -1)
                              );
                            } else if (selectedIndex > 0) {
                              newSelected = newSelected.concat(
                                selectedArray.slice(0, selectedIndex),
                                selectedArray.slice(selectedIndex + 1)
                              );
                            }
                            setSelectedArray(newSelected);
                          }}
                          className={
                            index === 0 && item?.filters?.length !== 1
                              ? "inner-dap-policy"
                              : ""
                          }
                          key={index}
                        >
                          {index === 0 && (
                            <>
                              <TableCell
                                rowSpan={item?.filters?.length}
                                align="left"
                              >
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                />
                              </TableCell>
                              <TableCell rowSpan={item?.filters?.length}>
                                {item?.name}
                              </TableCell>
                            </>
                          )}
                          <TableCell align="right">
                            {filter?.entityName}
                          </TableCell>
                          <TableCell align="right">
                            {filter?.attributeName}
                          </TableCell>
                          <TableCell align="right">
                            {filter?.attributeValueName}
                          </TableCell>

                          {index === 0 && (
                            <TableCell
                              align="right"
                              rowSpan={item?.filters?.length}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/application/dap/assign-policy/${id}/${item?.id}`
                                    )
                                  }
                                >
                                  <AccountCircleIcon />
                                </IconButton>

                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/application/dap/edit-policy/${id}/${item?.id}`
                                    )
                                  }
                                >
                                  <EditIcon />
                                </IconButton>

                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentPolicyId(item?.id);
                                    setCurrentPolicyName(item?.name);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow
                          selected={!!selectedArray.find((c) => c === item.id)}
                          sx={{ cursor: "pointer" }}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          onClick={() => {
                            const selectedIndex = selectedArray.findIndex(
                              (c) => c === item.id
                            );
                            let newSelected: any = [];

                            if (selectedIndex === -1) {
                              newSelected = newSelected.concat(
                                selectedArray,
                                item.id
                              );
                            } else if (selectedIndex === 0) {
                              newSelected = newSelected.concat(
                                selectedArray.slice(1)
                              );
                            } else if (
                              selectedIndex ===
                              selectedArray.length - 1
                            ) {
                              newSelected = newSelected.concat(
                                selectedArray.slice(0, -1)
                              );
                            } else if (selectedIndex > 0) {
                              newSelected = newSelected.concat(
                                selectedArray.slice(0, selectedIndex),
                                selectedArray.slice(selectedIndex + 1)
                              );
                            }
                            setSelectedArray(newSelected);
                          }}
                        >
                          <TableCell align="left">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                            />
                          </TableCell>
                          <TableCell>{item?.name}</TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/application/dap/assign-policy/${id}/${item?.id}`
                                )
                              }
                            >
                              <AccountCircleIcon />
                            </IconButton>

                            <IconButton
                              onClick={() => {
                                setCurrentPolicyId(item?.id);
                                setDeleteLoading(false);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
        {loading && <TableLoading />}
        {error && (
          <Typography
            component="div"
            style={{
              textAlign: "center",
              marginTop: "40px",
              marginBottom: "40px",
            }}
            variant="h6"
          >
            Something went wrong!
          </Typography>
        )}

        {data && data?.length === 0 && (
          <Typography
            component="div"
            style={{
              textAlign: "center",
              marginTop: "40px",
              marginBottom: "40px",
            }}
            variant="h6"
          >
            No Data!
          </Typography>
        )}
      </TableContainer>

      {!error && (showPagingDuringLoading || (!!totalPages && !loading)) && (
        <PaginationPolicy
          totalPages={totalPages}
          onChange={() => setShowPagingDuringLoading(true)}
        />
      )}

      <Dialog
        PaperProps={{
          style: {
            borderRadius: "24px",
            padding: "30px",
            gap: "14px",
          },
        }}
        open={!!currentPolicyId}
      >
        <DialogTitle className="lato center bold">Delete Policy</DialogTitle>
        <DialogContent>
          <DialogContentText className="lato center">
            Do you want to delete the policy “{currentPolicyName}“?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            disabled={deleteLoading}
            style={{ width: "120px", height: "37px" }}
            disableRipple
            className="btn-default-outlined"
            onClick={() => {
              setCurrentPolicyId(undefined);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            style={{ width: "120px" }}
            loading={deleteLoading}
            disableRipple
            className="btn-default"
            onClick={deletePolicy}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <ErrorNotifications error={deleteSnackBarError} />
    </div>
  );
};

export default DataAccessPolicies;
