import { FC } from "react";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

import './FileSubmited.scss';

type Props = {
    resetAll: Function;
};

const FileSubmited: FC<Props> = ({ resetAll }: Props) => {
    return (<Card style={{ marginTop: '10px' }} className="card without-border"
        variant="outlined">
        <div className="file-upload-subitem-wrapper">
            <h3 className="file-upload-subtitle">File submit</h3>
            <Alert style={{ marginTop: '10px' }} severity="success">File is successfully submitted</Alert>
            <Button
                style={{ marginTop: '15px' }}
                onClick={() => {
                    resetAll();
                }}
                disableRipple className="btn-default">
                Upload new file
            </Button>
        </div>

    </Card>);
};

export default FileSubmited;