import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { FC, useEffect, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import dayjs from "dayjs";

import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import './LogHistoryFiltering.scss';
import { getUserLogsEventsAPI } from '../../../api/logHistoryAPI';

type EventType = {
    value: number;
    label: string;
}

const LogHistoryFiltering: FC = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [userName, setUsername] = useState<any>(null);
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [eventType, setEventType] = useState<any>(null);

    const [eventTypes, setEventTypes] = useState<EventType[]>([]);
    const [eventTypesLoading, setEventTypesLoading] = useState(true);

    const fetchEventTypes = () => {
        setEventTypesLoading(true);

        getUserLogsEventsAPI().then((res: any) => {
            setEventTypes(
                res.map((e: any) => ({ value: e.id, label: e.name }))
            );
        }).finally(() => {
            setEventTypesLoading(false);
        })
    }


    useEffect(() => {
        setUsername(searchParams.get('userName'));
        if (searchParams.get('startDate')) {
            setStartDate(dayjs(searchParams.get('startDate')));
        }

        if (searchParams.get('endDate')) {
            setEndDate(dayjs(searchParams.get('endDate')));
        }

        if (searchParams.get('eventType')) {
            setEventType(eventTypes.find(c => String(c.value) === searchParams.get('eventType')));
        }


    }, [searchParams]);

    useEffect(() => {
        fetchEventTypes();
    }, []);

    return (<div className="log-history-filtering-wrapper">

        <TextField
            sx={{ width: 230 }}
            className="input small"
            variant="filled"
            name="username"
            value={userName}
            onChange={e => setUsername(e.target.value)}
            size="small"
            InputProps={{ disableUnderline: true }}
            label="Account" />

        <DatePicker
            label="Start Date"
            sx={{ width: 150 }}
            format="DD-MM-YYYY"

            slotProps={{
                textField: {
                    className: "input small",
                    size: 'small', variant: 'filled',
                    InputProps: { disableUnderline: true }
                },
                actionBar: {
                    actions: ['clear']
                }
            }}
            value={startDate}
            maxDate={endDate}
            onChange={(newValue) => setStartDate(newValue)}
        />

        <DatePicker
            label="End Date"
            sx={{ width: 150 }}
            format="DD-MM-YYYY"
            slotProps={{
                textField: {
                    className: "input small",
                    size: 'small', variant: 'filled',
                    InputProps: { disableUnderline: true }
                },
                actionBar: {
                    actions: ['clear']
                }
            }}
            minDate={startDate}
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
        />

        <Autocomplete
            disablePortal
            id="entity"
            sx={{ width: 175 }}
            className="input small-autocomplete"
            size="small"
            options={eventTypes}
            value={eventType}
            onChange={(event: any, newValue: any) => {
                setEventType(newValue);
            }}
            loading={true}
            loadingText={<CircularProgress color="inherit" style={{  textAlign: 'center' }} size={24} />}

            renderInput={(params) => <TextField
                variant="filled"
                {...params}
                InputProps={{ disableUnderline: true, ...params.InputProps }}
                label="Validation status" />}
        />

      

        <Button style={{ width: '120px' }}
            disabled={(startDate && !dayjs(startDate).isValid()) || (endDate && !dayjs(endDate).isValid())}
            onClick={() => {
                const params: any = {};
                if (userName?.trim()) {
                    params.userName = userName.trim();
                }

                if (startDate) {
                    params.startDate = dayjs(startDate).format('YYYY-MM-DD');
                }

                if (endDate) {
                    params.endDate = dayjs(endDate).format('YYYY-MM-DD');
                }

                if (eventType) {
                    params.eventType = eventType.value;
                }

                setSearchParams(createSearchParams(params));
            }}
            disableRipple className="btn-default">
            Apply Filter
        </Button>

        <Link
            className="link-default"
            onClick={() => {
                setUsername('');
                setStartDate(null);
                setEndDate(null);
                setEventType(null);
                setSearchParams(createSearchParams({}));
            }}
        >
            Reset filtering
        </Link>


    </div>);
};

export default LogHistoryFiltering;