import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import logo from '../../assets/logoAuth.svg';

import './Unauthorized.scss';

const Unauthorized: FC = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);


    return (
        <>
            <style nonce="NTYyYjNkNTMtNjgwMC00NDAyLWFjZWYtN2RhM2RhODk3MGQw">{"\
        body{\
         background-color: #f5f5f5;\
        }\
      "}</style>

            <div className="login-page">
                <div className="custom-card">
                    <div className="login-container">
                        <img src={logo} alt="Majorel" />
                        <div style={{ height: '280px', width: '1px', background: '#E0E0E0', }}></div>
                        <div className="login-functionality">
                            <h1>Welcome</h1>
                            <h3>Please, sign in, to continue</h3>
                            <div className="btn" onClick={() => instance.loginPopup()}>Sign in with Majorel AD</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Unauthorized;