import { FC, useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

type Props = {
  element: any;
  entitiesAttributes: any;
  setAddList: any;
  saveLoading: boolean;
  addList: any;
  setShowTheSameUserError: any;
  index: any;
  disableSave: Function;
};

const EditPolicyRow: FC<Props> = ({
  element,
  entitiesAttributes,
  setAddList,
  saveLoading,
  addList,
  setShowTheSameUserError,
  index,
  disableSave,
}: Props) => {
  const [editMode, setEditMode] = useState(false);

  const [currentEntityId, setCurrentEntityId] = useState<any>();
  const [currentAttributeId, setCurrentAttributeId] = useState<any>();
  const [currentAttributeValueId, setCurrentAttributeValueId] = useState<any>();


  useEffect(() => {
    function callback(e: unknown) {
        if ((e as CustomEvent).detail !== index && editMode) {
            if (editMode) {
                disableSave(false);
                setEditMode(false);
            }
        }
    }
    window.addEventListener('edit_mode_index', callback);
    return () => {
        window.removeEventListener('edit_mode_index', callback);
    };
});

  const entityMemoizedValue = useMemo(() => {
    return entitiesAttributes?.find((c: any) => c.value === currentEntityId)
      ?.label;
  }, [entitiesAttributes, currentEntityId]);

  const attributeMemoizedValue = useMemo(() => {
    const entity = entitiesAttributes?.find(
      (c: any) => c.value === currentEntityId
    );
    if (!entity) return null;
    return entity?.attributes.find((a: any) => a?.value === currentAttributeId);
  }, [entitiesAttributes, currentEntityId, currentAttributeId]);

  const attributeValueMemoizedValue = useMemo(() => {
    const entity = entitiesAttributes?.find(
      (c: any) => c.value === currentEntityId
    );
    if (!entity) return null;
    const attribute = entity?.attributes.find(
      (a: any) => a?.value === currentAttributeId
    );
    if (!attribute) return null;
    return attribute.attributeValues.find(
      (a: any) => a?.value === currentAttributeValueId
    );
  }, [
    entitiesAttributes,
    currentEntityId,
    currentAttributeId,
    currentAttributeValueId,
  ]);

  const memoizedAttributes = useMemo(() => {
    const entity = entitiesAttributes?.find(
      (c: any) => c.value === currentEntityId
    );
    if (!entity) return [];
    return entity?.attributes;
  }, [entitiesAttributes, currentEntityId]);

  const memoizedAttributeValues = useMemo(() => {
    const entity = entitiesAttributes?.find(
      (c: any) => c.value === currentEntityId
    );
    if (!entity) return [];
    const attribute = entity?.attributes.find(
      (a: any) => a?.value === currentAttributeId
    );
    if (!attribute) return null;
    return attribute?.attributeValues;
  }, [entitiesAttributes, currentEntityId, currentAttributeId]);

  if (editMode) {
    return (
      <TableRow
        style={{ height: "80px" }}
        key={
          String(element?.entityId) +
          String(element?.attributeId) +
          String(element?.attributeValueId)
        }
      >
        <TableCell>
          <Autocomplete
            disablePortal
            id="entity"
            sx={{ width: 200 }}
            className="input small"
            size="small"
            options={entitiesAttributes}
            value={entityMemoizedValue || null}
            onChange={(event: any, newValue: any) => {
              setCurrentEntityId(newValue?.value);
              setCurrentAttributeId(null);
            }}
            renderInput={(params) => (
              <TextField
                variant="filled"
                {...params}
                InputProps={{ disableUnderline: true, ...params.InputProps }}
                label="Entity"
              />
            )}
          />
        </TableCell>
        <TableCell align="left">
          <Autocomplete
            disablePortal
            id="attribute"
            sx={{ width: 200 }}
            className="input small"
            size="small"
            disabled={!currentEntityId}
            options={memoizedAttributes}
            value={attributeMemoizedValue || null}
            onChange={(event: any, newValue: any) => {
              setCurrentAttributeId(newValue?.value);
              setCurrentAttributeValueId(null);
            }}
            renderInput={(params) => (
              <TextField
                variant="filled"
                {...params}
                InputProps={{ disableUnderline: true, ...params.InputProps }}
                label="Attribute"
              />
            )}
          />
        </TableCell>
        <TableCell align="left">
          <Autocomplete
            disablePortal
            id="attribute"
            sx={{ width: 200 }}
            className="input small"
            size="small"
            disabled={!currentAttributeId}
            options={memoizedAttributeValues}
            value={attributeValueMemoizedValue || null}
            onChange={(event: any, newValue: any) => {
              setCurrentAttributeValueId(newValue?.value);
            }}
            renderInput={(params) => (
              <TextField
                variant="filled"
                {...params}
                InputProps={{ disableUnderline: true, ...params.InputProps }}
                label="Attribute Value"
              />
            )}
          />
        </TableCell>
        <TableCell align="right">
          <IconButton
            disabled={
              saveLoading ||
              !currentAttributeId ||
              !currentAttributeValueId ||
              !currentEntityId
            }
            onClick={() => {
              if (
                addList
                  .filter((_: any, i: any) => index !== i)
                  .find(
                    (filter: any) =>
                      String(filter?.entityId) + String(filter?.attributeId) ===
                      String(currentEntityId) + String(currentAttributeId)
                  )
              ) {
                setShowTheSameUserError(true);
              } else {
                const newArr = [...addList];
                newArr[index].attributeValueId = currentAttributeValueId;
                newArr[index].entityId = currentEntityId;
                newArr[index].attributeId = currentAttributeId;

                setAddList(newArr);
                setEditMode(false);
                disableSave(false);
              }
            }}
          >
            <SaveIcon />
          </IconButton>

          <IconButton
            disabled={saveLoading}
            onClick={() => {
              disableSave(false);
              setAddList((oldAddList: any) =>
                oldAddList.filter(
                  (el: any) =>
                    String(el?.entityId) +
                      String(el?.attributeId) +
                      String(el?.attributeValueId) !==
                    String(element?.entityId) +
                      String(element?.attributeId) +
                      String(element?.attributeValueId)
                )
              );
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow
      key={
        String(element?.entityId) +
        String(element?.attributeId) +
        String(element?.attributeValueId)
      }
    >
      <TableCell>
        {
          entitiesAttributes?.find((c: any) => c.value === element?.entityId)
            ?.label
        }
      </TableCell>
      <TableCell align="right">
        {
          entitiesAttributes
            ?.find((c: any) => c.value === element?.entityId)
            ?.attributes.find((a: any) => a?.value === element?.attributeId)
            ?.label
        }
      </TableCell>
      <TableCell align="right">
        {
          entitiesAttributes
            ?.find((c: any) => c.value === element?.entityId)
            ?.attributes.find((a: any) => a?.value === element?.attributeId)
            ?.attributeValues.find(
              (a: any) => a?.value === element?.attributeValueId
            )?.label
        }
      </TableCell>
      <TableCell align="right">
        <IconButton
          disabled={saveLoading}
          onClick={() => {
            setCurrentEntityId(element?.entityId);
            setCurrentAttributeId(element?.attributeId);
            setCurrentAttributeValueId(element?.attributeValueId);
            setEditMode(true);
            window.dispatchEvent(
              new CustomEvent("edit_mode_index", {
                detail: index,
              })
            );
            disableSave(true);
          }}
        >
          <EditIcon />
        </IconButton>

        <IconButton
          disabled={saveLoading}
          onClick={() => {
            setAddList((oldAddList: any) =>
              oldAddList.filter(
                (el: any) =>
                  String(el?.entityId) +
                    String(el?.attributeId) +
                    String(el?.attributeValueId) !==
                  String(element?.entityId) +
                    String(element?.attributeId) +
                    String(element?.attributeValueId)
              )
            );
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default EditPolicyRow;
