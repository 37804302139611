import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/system/Stack";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FilePresentIcon from '@mui/icons-material/FilePresent';

import ErrorNotifications from "../../../components/ErrorNotifications";
import UserSelectAssignPolicy from "../../../components/UserSelectAssignPolicy";
import AssignPolicyTableHeader from "../../../components/AssignPolicyTableHeader";

import {
  exportDapFileAPI,
  getDapAssignedUsersAPI,
  saveDapAssignedUsersAPI,
} from "../../../api/policiesAPI";

import { downloadFile, formatDate, goBackApplications } from "../../../util/helpers";
import { UserSelectAssignPolicyDTO } from "../../../models/UserSelectAssignPolicyDTO";

import "./AssignPolicy.scss";

const AssignPolicy: FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [users, setUsers] = useState<any>([]);
  const [metaData, setMetaData] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState<any>();
  let { applicationId, policyId } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userSelect, setUserSelect] =
    useState<UserSelectAssignPolicyDTO | null>(null);
  const [showTheSameUserError, setShowTheSameUserError] = useState<any>(false);
  const [selectedArray, setSelectedArray] = useState<Array<any>>([]);

  useEffect(() => {
    if (policyId) {
      setLoading(true);
      getDapAssignedUsersAPI(policyId)
        .then((res) => {
          const { users } = res;
          setMetaData(res);
          setUsers(users || []);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [policyId]);

  useEffect(() => {
    if (userSelect) {
      setEmail(userSelect.email);
      setFirstName(userSelect.firstName);
      setLastName(userSelect.lastName);
    } else {
      setEmail("");
      setFirstName("");
      setLastName("");
    }
  }, [userSelect]);

  const filteredUsers = useMemo(() => {
    return users.filter((c: any) => c?.status !== "DELETED");
  }, [users]);

  const onChangeFirstName = (e: any) => setFirstName(e.target.value);
  const onChangeLastName = (e: any) => setLastName(e.target.value);

  const resetForm = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setUserSelect(null);
  };

  const onAssignUser = () => {
    if (
      users.find(
        (u: any) =>
          firstName?.trim().toLowerCase() +
            lastName?.trim().toLowerCase() +
            email?.trim().toLowerCase() ===
          u?.firstName?.trim().toLowerCase() +
            u?.lastName?.trim().toLowerCase() +
            u?.email?.trim().toLowerCase()
      )
    ) {
      setShowTheSameUserError(true);
    } else {
      setUsers((users: any) => [
        ...users,
        {
          firstName: firstName?.trim(),
          lastName: lastName?.trim(),
          email: email?.trim(),
          id: Math.random() * 10000,
          status: "NEW",
        },
      ]);
      resetForm();
    }
  };

  if (loading) {
    return (
      <div className="assign-policy-wrapper">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="assign-policy-wrapper">
        <Typography
          component="div"
          style={{
            textAlign: "center",
            marginTop: "40px",
            marginBottom: "40px",
          }}
          variant="h6"
        >
          Something went wrong!
        </Typography>
      </div>
    );
  }
  return (
    <div className="assign-policy-wrapper">
      <div className="back-construction" style={{ marginBottom: "10px" }}>
        <ChevronLeftIcon
          onClick={() => {
            goBackApplications(navigate, applicationId);
          }}
          className="icon-back"
        />
        <h2 className="app-header-title">
          Application Service {metaData?.applicationName}{" "}
          {metaData?.applicationCode}
          <br />
          {metaData?.name}
        </h2>
      </div>

      <Card className="card without-border" variant="outlined">
        <div className="current-app-data" style={{ flexDirection: "row" }}>
          <Stack>
            <TextField
              sx={{ width: 200 }}
              className="input"
              variant="filled"
              required
              disabled
              name="firstname"
              value={firstName}
              onChange={onChangeFirstName}
              size="small"
              InputProps={{ disableUnderline: true }}
              label="First Name"
            />
          </Stack>

          <Stack>
            <TextField
              sx={{ width: 200 }}
              className="input"
              variant="filled"
              value={lastName}
              name="lastname"
              onChange={onChangeLastName}
              required
              disabled
              size="small"
              InputProps={{ disableUnderline: true }}
              label="Last Name"
            />
          </Stack>

          <Stack>
            <UserSelectAssignPolicy
              value={userSelect}
              setValue={setUserSelect}
            />
          </Stack>
          <Stack>
            <Button
              disabled={
                !firstName?.trim() ||
                !lastName?.trim() ||
                !email?.trim() ||
                saveLoading
              }
              style={{ width: "120px", height: "52px" }}
              disableRipple
              onClick={() => onAssignUser()}
              className="btn-default"
            >
              Assign
            </Button>
          </Stack>
        </div>

        <AssignPolicyTableHeader
          numSelected={selectedArray.length}
          rowCount={filteredUsers.length}
          onDelete={() => {
            const del = (element: any) => {
              let array = [...users];
              const index = array.findIndex((el) => el?.id === element?.id);

              if (array[index]?.status === "NEW") {
                array = array.filter((c) => c?.id !== element?.id);
              } else {
                array[index].status = "DELETED";
              }
              setUsers(array);
              setSelectedArray([]);
            };

            selectedArray.forEach((element) => del(element));
          }}
        />
        <div
          className="current-app-data"
          style={{ flexDirection: "row", paddingTop: "0" }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 400 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selectedArray.length > 0 &&
                        selectedArray.length < filteredUsers?.length
                      }
                      checked={
                        filteredUsers?.length > 0 &&
                        selectedArray.length === filteredUsers?.length
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.checked) {
                          setSelectedArray([...filteredUsers]);
                          return;
                        }
                        setSelectedArray([]);
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">First name</TableCell>
                  <TableCell align="left">Last name</TableCell>
                  <TableCell align="left">User ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((element: any) => {
                  const isItemSelected = !!selectedArray.find(
                    (c) => c.id === element.id
                  );
                  return (
                    <TableRow
                      selected={
                        !!selectedArray.find((c) => c.id === element.id)
                      }
                      sx={{ cursor: "pointer" }}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      onClick={() => {
                        const selectedIndex = selectedArray.findIndex(
                          (c) => c.id === element.id
                        );
                        let newSelected: any = [];

                        if (selectedIndex === -1) {
                          newSelected = newSelected.concat(
                            selectedArray,
                            element
                          );
                        } else if (selectedIndex === 0) {
                          newSelected = newSelected.concat(
                            selectedArray.slice(1)
                          );
                        } else if (selectedIndex === selectedArray.length - 1) {
                          newSelected = newSelected.concat(
                            selectedArray.slice(0, -1)
                          );
                        } else if (selectedIndex > 0) {
                          newSelected = newSelected.concat(
                            selectedArray.slice(0, selectedIndex),
                            selectedArray.slice(selectedIndex + 1)
                          );
                        }
                        setSelectedArray(newSelected);
                      }}
                      key={element?.id}
                    >
                      <TableCell align="left">
                        <Checkbox color="primary" checked={isItemSelected} />
                      </TableCell>

                      <TableCell align="left">{element?.firstName}</TableCell>
                      <TableCell align="left">{element?.lastName}</TableCell>
                      <TableCell align="left">{element?.email}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {filteredUsers && filteredUsers?.length === 0 && (
              <Typography
                component="div"
                style={{
                  textAlign: "center",
                  marginTop: "40px",
                  marginBottom: "40px",
                }}
                variant="h6"
              >
                No Data!
              </Typography>
            )}
          </TableContainer>
        </div>

        <div
          className="current-app-data"
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            paddingTop: "0",
          }}
        >
          <LoadingButton
            loading={exportLoading}

            style={{ width: "160px", height: "52px" }}
            onClick={() => {
              setExportLoading(true);
              exportDapFileAPI({
                policyName: null, //"",
                userId: null, //"",
                policyIds: [Number(policyId)],
                applicationId: Number(applicationId),
              }).then((blob) => downloadFile(
                blob, `Policy_export_${formatDate(new Date())}.xlsx`
              )).catch(err => {
                setSaveError(err);
              }).finally(() => {
                setExportLoading(false);
              })
            }}
            disableRipple
            className="btn-default"
            startIcon={<FilePresentIcon />}
          >
            Export Policy
          </LoadingButton>

          <LoadingButton
            loading={saveLoading}
            disabled={
              saveLoading ||
              !users?.length ||
              (metaData?.users?.length === users?.length &&
                metaData?.users?.every((c: any) => {
                  const found = users?.find((s: any) => s.id === c.id);
                  return found && !found?.status;
                }))
            }
            style={{ width: "120px", height: "52px" }}
            onClick={() => {
              setSaveLoading(true);
              saveDapAssignedUsersAPI(policyId, {
                deletedUsers: [
                  ...users
                    .filter((c: any) => c?.status === "DELETED")
                    .map((a: any) => a?.id),
                ],
                addedUsers: [
                  ...users
                    .filter((c: any) => c?.status === "NEW")
                    .map((a: any) => ({
                      email: a?.email,
                      firstName: a?.firstName,
                      lastName: a?.lastName,
                    })),
                ],
              })
                .then((res) => {
                  enqueueSnackbar("Assignment(s) Changed Successfully!", {
                    variant: "success",
                    autoHideDuration: 3000,
                    anchorOrigin: { vertical: "top", horizontal: "center" },
                  });
                  navigate(`/application/${applicationId}`);
                })
                .catch((err) => setSaveError(err))
                .finally(() => {
                  setSaveLoading(false);
                });
            }}
            disableRipple
            className="btn-default"
          >
            Save
          </LoadingButton>
        </div>
      </Card>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showTheSameUserError}
        autoHideDuration={3000}
        onClose={() => setShowTheSameUserError(false)}
        key={0}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setShowTheSameUserError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          This user already exists!
        </Alert>
      </Snackbar>

      <ErrorNotifications error={saveError} />
    </div>
  );
};

export default AssignPolicy;
