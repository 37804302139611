import { FC, useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import TableLoading from "../../../components/TableLoading";
import ErrorNotifications from "../../../components/ErrorNotifications";
import PaginationPolicy from "../../../components/PaginationPolicy/PaginationPolicy";
import TableFilterPolicy from "../../../components/TableFilterPolicy/TableFilterPolicy";
import OrderingPolicy from "../../../components/OrderingPolicy/OrderingPolicy";

import { getCurrentApplicationObjectAccessPoliciesAPI } from "../../../api/applicationsAPI";
import { deleteOapPolicyAPI } from "../../../api/policiesAPI";
import { policiesQueryParams } from "../../../util/helpers";

import './ObjectAccessPolicies.scss';

const ObjectAccessPolicies: FC = () => {
    const [currentPolicyId, setCurrentPolicyId] = useState<any>();

    const [showPagingDuringLoading, setShowPagingDuringLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>();
    const [deleteLoading, setDeleteLoading] = useState<any>();
    const [deleteSnackBarError, setDeleteSnackBarError] = useState<any>();

    const navigate = useNavigate();
    let { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const { enqueueSnackbar } = useSnackbar();

    const fetchData = (id: any, searchParams: any) => {
        setData(undefined);
        setLoading(true);
        getCurrentApplicationObjectAccessPoliciesAPI(id, policiesQueryParams(searchParams)).then((res: any) => {
            setData(res?.policies);
            setTotalPages(res?.totalPages);
        })
            .catch((err: any) => { setError(err); })
            .finally(() => {
                setLoading(false);
                setShowPagingDuringLoading(false);
            });
    };

    useEffect(() => {
        if (id) {
            fetchData(id, searchParams);
        }
    }, [id, searchParams]);

    return (<div style={{ marginTop: '30px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <h2 className="table-title">Object Access Policies</h2>
            {/* <Button onClick={() => navigate(`/application/add-policy/${id}`)}
                className="btn-default-outlined" disableRipple>Add Policy</Button> */}
        </div>

        <TableFilterPolicy inputPolicyLabel="Object Policy Name" inputPolicyName="objectPolicyName" />

        <TableContainer>
            <Table sx={{ minWidth: 800 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: 'none!important'
                            }}>
                                Object Policy Name <OrderingPolicy noAction={data && data?.length === 0} onChange={() => setShowPagingDuringLoading(true)} />
                            </div>
                        </TableCell>
                        <TableCell align="right">Object Code</TableCell>
                        <TableCell align="right">Object Type</TableCell>
                        <TableCell align="right">Permission</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {data && data.map((item: any) => (
                        <TableRow key={item?.id}>
                            <TableCell>
                                {item?.name}
                            </TableCell>
                            <TableCell align="right">
                                {item?.code}
                            </TableCell>
                            <TableCell align="right">
                                {item?.type}
                            </TableCell>
                            <TableCell align="right">
                                {item?.permission}
                            </TableCell>

                            <TableCell align="right">
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton onClick={() => navigate(`/application/oap/assign-policy/${id}/${item?.id}`)}>
                                        <AccountCircleIcon />
                                    </IconButton>

                                    <IconButton style={{ display: 'none' }} onClick={() => setCurrentPolicyId(item?.id)}>
                                        <DeleteIcon />
                                    </IconButton>

                                </div>
                            </TableCell>
                        </TableRow>))}
                </TableBody>
            </Table>
            {loading && <TableLoading />}
            {error && <Typography component="div" style={{
                textAlign: 'center',
                marginTop: '40px',
                marginBottom: '40px'
            }} variant="h6">
                Something went wrong!
            </Typography>}

            {data && data?.length === 0 && <Typography component="div" style={{
                textAlign: 'center',
                marginTop: '40px',
                marginBottom: '40px'
            }} variant="h6">
                No Data!
            </Typography>}

        </TableContainer>

        {(!error && (showPagingDuringLoading || (!!totalPages && !loading))) && <PaginationPolicy totalPages={totalPages} onChange={() => setShowPagingDuringLoading(true)} />}

        <Dialog
            PaperProps={{
                style: {
                    borderRadius: '24px',
                    padding: '30px',
                    gap: '14px',
                }
            }}
            open={!!currentPolicyId}>
            <DialogTitle className="lato center bold">Delete Policy</DialogTitle>
            <DialogContent>
                <DialogContentText className="lato center">
                    Do you want to delete the Policy?
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button disabled={deleteLoading} style={{ width: '120px', height: '37px' }} disableRipple className="btn-default-outlined" onClick={() => { setCurrentPolicyId(undefined) }}>Cancel</Button>
                <LoadingButton style={{ width: '120px' }} loading={deleteLoading}
                    disableRipple className="btn-default"
                    onClick={() => {
                        setDeleteLoading(true);
                        deleteOapPolicyAPI(currentPolicyId).then(() => {
                            setShowPagingDuringLoading(true);
                            if (data?.length === 1) {
                                if (searchParams.get('page')) {
                                    if (searchParams.get('page') === '2') {
                                        setSearchParams((searchParams) => {
                                            searchParams.delete('page');
                                            return searchParams;
                                        });
                                    } else {
                                        if (Number(searchParams.get('page')) === totalPages) {
                                            setSearchParams((searchParams) => {
                                                searchParams.set('page', String(Number(searchParams.get('page')) - 1));
                                                return searchParams;
                                            });
                                        } else {
                                            fetchData(id, searchParams);
                                        }
                                    }
                                } else {
                                    fetchData(id, searchParams);
                                }

                            } else {
                                fetchData(id, searchParams);
                            }

                            // setData((oldData: any) => oldData?.filter((item: any) => item?.id !== currentPolicyId));
                            setCurrentPolicyId(undefined);
                            enqueueSnackbar('Data Access Policy is deleted!', {
                                variant: "success",
                                autoHideDuration: 3000,
                                anchorOrigin: { vertical: "top", horizontal: "center" }
                            });
                        }).catch((err) => {
                            setDeleteSnackBarError(err);
                            // setDeleteSnackBarMessage('Something went wrong!');
                        }).finally(() => {
                            setDeleteLoading(false);
                        })

                    }}>Delete</LoadingButton>
            </DialogActions>
        </Dialog>

        <ErrorNotifications error={deleteSnackBarError} />

    </div>);
};

export default ObjectAccessPolicies;