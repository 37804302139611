import { Configuration, InteractionRequiredAuthError, LogLevel, PublicClientApplication } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: `${process.env.REACT_APP_AAD_CLIENT_ID}`,
        authority: `${process.env.REACT_APP_AAD_AUTHORITY}`,
        redirectUri: `${process.env.REACT_APP_AAD_REDIRECT_URI}`,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              console.log(message);
            }
          },
        },
      },
};

export const loginRequest = {
  // scopes: ['.default'],
     scopes: [`${process.env.REACT_APP_AAD_CLIENT_ID}/.default`],
    // scopes: [`${process.env.REACT_APP_AAD_CLIENT_ID}`, "https://graph.microsoft.com/Files.Read",],
};

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.handleRedirectPromise();

export const acquireToken = async () => {
    const accounts = msalInstance.getAllAccounts();
    if (!accounts.length) return null;
    try {
        return await msalInstance.acquireTokenSilent({ ...loginRequest, account: accounts[0] });
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          // return msalInstance.acquireTokenPopup({ ...loginRequest, account: accounts[0] });
          return msalInstance.logoutPopup();
        }
        throw error;
    }
};
