import { FC } from "react";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";

import { getTemplateFileAPI } from "../../../api/fileUploadAPI";

import { downloadFile } from "../../../util/helpers";

import './DownloadTemplate.scss';

type Props = {
    label: string;
    value: string;
}

const DownloadTemplate: FC<Props> = ({label, value}: Props) => {

    return (<>
        <Card style={{ marginTop: '10px' }} className="card without-border"
            variant="outlined">
            <div className="file-upload-subitem-wrapper">
                <h3 className="file-upload-subtitle">Download template</h3>
                <p style={{marginTop: '10px'}}>
                    Template for {label} can be downloaded <Link onClick={() => {
                        getTemplateFileAPI(value).then((blob) => downloadFile(
                            blob, 'template.xlsx'
                        ));
                    }}>here</Link>
                </p>
            </div>
        </Card>
    </>);
}

export default DownloadTemplate;