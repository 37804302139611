import { FC, useEffect, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import dayjs from "dayjs";
// import utc from 'dayjs/plugin/utc';

import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import './UploadHistoryFiltering.scss';
import { getUploadTemplatesAPI } from "../../../api/fileUploadAPI";
import { templateType } from "../../../util/helpers";


const validation = [{ label: 'Success', value: 'success' }, { label: 'Fail', value: 'fail' }];
const submission = [{ label: 'Submitted', value: 'submitted' }, { label: 'Not Submitted', value: 'notSubmitted' }];
const logs = [{ label: 'Archived', value: 'archived' }, { label: 'Active', value: 'active' }];
// [{ label: 'Archived', value: 'archived' }, { label: 'Active', value: 'active' }];
// dayjs.extend(utc);

const UploadHistoryFiltering: FC = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [userName, setUsername] = useState<any>(null);
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [validated, setValidated] = useState<any>(null);
    const [submitted, setSubmitted] = useState<any>(null);
    const [archived, setArchived] = useState<any>(null);
    const [templateId, setTemplateId] = useState<any>(null);
    const [templates, setTemplates] = useState<any>([]);

    useEffect(() => {
        getUploadTemplatesAPI().then((res) => {
            setTemplates(res.map((c: any) => ({ label: templateType(c.type), value: c.id })));
        });
    }, []);


    useEffect(() => {
        setUsername(searchParams.get('userName'));
        if (searchParams.get('startDate')) {
            setStartDate(dayjs(searchParams.get('startDate')));
        }

        if (searchParams.get('endDate')) {
            setEndDate(dayjs(searchParams.get('endDate')));
        }

        if (searchParams.get('validated')) {
            setValidated(searchParams.get('validated') === 'true' ? validation[0] : validation[1]);
        }

        if (searchParams.get('submitted')) {
            setSubmitted(searchParams.get('submitted') === 'true' ? submission[0] : submission[1]);
        }

        if (searchParams.get('archived')) {
            setArchived(searchParams.get('archived') === 'true' ? logs[0] : logs[1]);
        }


        if (searchParams.get('templateId')) {
            if (templates.length) {
                setTemplateId(templates.find((c: any) => c.value === searchParams.get('templateId')?.trim()));
            } else {
                setTemplateId({ label: searchParams.get('templateId'), value: searchParams.get('templateId') });
            }
        }

    }, [searchParams, templates]);

    return (<div className="upload-history-filtering-wrapper">

        <TextField
            sx={{ width: 230 }}
            className="input small"
            variant="filled"
            name="username"
            value={userName}
            onChange={e => setUsername(e.target.value)}
            size="small"
            InputProps={{ disableUnderline: true }}
            label="Username" />

        <DatePicker
            label="Start Date"
            sx={{ width: 150 }}
            format="DD-MM-YYYY"

            slotProps={{
                textField: {
                    className: "input small",
                    size: 'small', variant: 'filled',
                    InputProps: { disableUnderline: true }
                },
                actionBar: {
                    actions: ['clear']
                }
            }}
            value={startDate}
            maxDate={endDate}
            onChange={(newValue) => setStartDate(newValue)}
        />

        <DatePicker
            label="End Date"
            sx={{ width: 150 }}
            format="DD-MM-YYYY"
            slotProps={{
                textField: {
                    className: "input small",
                    size: 'small', variant: 'filled',
                    InputProps: { disableUnderline: true }
                },
                actionBar: {
                    actions: ['clear']
                }
            }}
            minDate={startDate}
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
        />

        <Autocomplete
            disablePortal
            id="entity"
            sx={{ width: 150 }}
            className="input small-autocomplete"
            size="small"
            options={validation}
            value={validated}
            onChange={(event: any, newValue: any) => {
                setValidated(newValue);
            }}

            renderInput={(params) => <TextField
                variant="filled"
                {...params}
                InputProps={{ disableUnderline: true, ...params.InputProps }}
                label="Validation status" />}
        />

        <Autocomplete
            disablePortal
            id="entity"
            sx={{ width: 150 }}
            className="input small-autocomplete"
            size="small"
            options={submission}
            value={submitted}
            onChange={(event: any, newValue: any) => {
                setSubmitted(newValue);
            }}
            renderInput={(params) => <TextField
                variant="filled"
                {...params}
                InputProps={{ disableUnderline: true, ...params.InputProps }}
                label="File status" />}
        />

        <Autocomplete
            disablePortal
            id="entity"
            sx={{ width: 150 }}
            className="input small-autocomplete"
            size="small"
            options={logs}
            value={archived}
            onChange={(event: any, newValue: any) => {
                setArchived(newValue);
            }}
            renderInput={(params) => <TextField
                variant="filled"
                {...params}
                InputProps={{ disableUnderline: true, ...params.InputProps }}
                label="Log status" />}
        />

        <Autocomplete
            disablePortal
            id="entity"
            sx={{ width: 150 }}
            className="input small-autocomplete"
            size="small"
            options={templates}
            value={templateId}
            onChange={(event: any, newValue: any) => {
                setTemplateId(newValue);
            }}
            renderInput={(params) => <TextField
                variant="filled"
                {...params}
                InputProps={{ disableUnderline: true, ...params.InputProps }}
                label="Template type" />}
        />

        <Button style={{ width: '120px' }}
            disabled={(startDate && !dayjs(startDate).isValid()) || (endDate && !dayjs(endDate).isValid())}
            onClick={() => {
                const params: any = {};
                if (userName?.trim()) {
                    params.userName = userName.trim();
                }

                if (startDate) {
                    params.startDate = dayjs(startDate).format('YYYY-MM-DDTHH:mm:ssZ');
                }

                if (endDate) {
                    params.endDate = dayjs(endDate).format('YYYY-MM-DDTHH:mm:ssZ');
                }

                if (validated) {
                    params.validated = validated.value === 'success' ? 'true' : 'false';
                }

                if (submitted) {
                    params.submitted = submitted.value === 'submitted' ? 'true' : 'false';
                }

                if (archived) {
                    params.archived = archived.value === 'archived' ? 'true' : 'false';
                }

                if (templateId) {
                    params.templateId = templateId.value;
                }

                setSearchParams(createSearchParams(params));
            }}
            disableRipple className="btn-default">
            Apply Filter
        </Button>

        <Link
            className="link-default"
            onClick={() => {
                setUsername('');
                setStartDate(null);
                setEndDate(null);
                setValidated(null);
                setSubmitted(null);
                setArchived(null);
                setTemplateId(null);
                setSearchParams(createSearchParams({}));
            }}
        >
            Reset filtering
        </Link>


    </div>);
};

export default UploadHistoryFiltering;