import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import './OrderingPolicy.scss';


type IconProps = {
    active?: boolean;
}

const AscIcon: FC<IconProps> = ({ active }) => {
    return <svg className="ordering-policy" fill={active ? '#212121' : '#999999'} xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path d="M19.707 14.707A1 1 0 0 0 19 13h-7v2h4.586l-4.293 4.293A1 1 0 0 0 13 21h7v-2h-4.586l4.293-4.293zM7 3.99H5v12H2l4 4 4-4H7zM17 3h-2c-.417 0-.79.259-.937.649l-2.75 7.333h2.137L14.193 9h3.613l.743 1.981h2.137l-2.75-7.333A1 1 0 0 0 17 3zm-2.057 4 .75-2h.613l.75 2h-2.113z" /></svg>;
}

const DescIcon: FC<IconProps> = ({ active }) => {
    return <svg className="ordering-policy" fill={active ? '#212121' : '#999999'} xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path d="M19.707 14.707A1 1 0 0 0 19 13h-7v2h4.586l-4.293 4.293A1 1 0 0 0 13 21h7v-2h-4.586l4.293-4.293zM6 3.99l-4 4h3v12h2v-12h3zM17 3h-2c-.417 0-.79.259-.937.649l-2.75 7.333h2.137L14.193 9h3.613l.743 1.981h2.137l-2.75-7.333A1 1 0 0 0 17 3zm-2.057 4 .75-2h.613l.75 2h-2.113z" /></svg>;
}

type OrderingPolicyProps = {
    noAction?: boolean;
    onChange: Function;
}

const OrderingPolicy: FC<OrderingPolicyProps> = ({ noAction, onChange }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    let active = searchParams.get('orderBy');

    return <div onClick={() => {
        if (noAction) return;
        setSearchParams((searchParams) => {
            const status = searchParams.get('orderBy');
            if (status === null) {
                searchParams.set('orderBy', 'asc');
            } else if (status === 'asc') {
                searchParams.set('orderBy', 'desc');
            } else if (status === 'desc') {
                searchParams.set('orderBy', 'asc');
            }
            return searchParams;
        });
        onChange();
    }}>
        {(active === 'asc' || active === null) && <AscIcon active={!!active} />}
        {active === 'desc' && <DescIcon active={!!active} />}
    </div>;
};

export default OrderingPolicy;