import { FC, useEffect } from "react";

import { useSnackbar } from "notistack";

type Props = {
    error?: any;
};

const Notification: FC<Props> = ({ error }: Props) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (error) {
            if (Array.isArray(error?.errors)) {
                error?.errors.forEach((el: any) => {
                    enqueueSnackbar(el?.message, {
                        variant: "error",
                        autoHideDuration: 3000,
                        anchorOrigin: { vertical: "top", horizontal: "center" }
                    });
                });
            } else {
                enqueueSnackbar('Something went wrong!', {
                    variant: "error",
                    autoHideDuration: 3000,
                    anchorOrigin: { vertical: "top", horizontal: "center" }
                });
            }
        }
    }, [error, enqueueSnackbar]);

    return null;
};



const ErrorNotifications: FC<Props> = (props: Props) => {
    return <Notification {...props} />;
};

export default ErrorNotifications;