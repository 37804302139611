import { FC, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import FileUploadLoadingState from "../FileUploadLoadingState/FileUploadLoadingState";
import FileUploadErrorState from "../FileUploadErrorState/FileUploadErrorState";

import { getUploadTemplatesAPI } from "../../../api/fileUploadAPI";
import { templateType } from "../../../util/helpers";
import { TemplateDTO } from "../../../models/TemplateDTO";
import { ErrorDTO } from "../../../models/ErrorDTO";

import './SelectTemplate.scss';

type Props = {
    onChange?: any;
    value?: string;
    disabled?: boolean;
}

const SelectTemplate: FC<Props> = ({ onChange, value, disabled }: Props) => {
    const [list, setList] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<ErrorDTO>();
    const [inputValue, setInputValue] = useState('');
    
    useEffect(() => {
        getUploadTemplatesAPI().then((res: Array<TemplateDTO>) => {
            setList(res.map((item: TemplateDTO) => {
                return {
                    value: item.id,
                    label: templateType(item?.type)
                };
            }));
        }).catch((err) => {
            setError(err);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    if (loading) {
        return (<div style={{ marginTop: '30px' }}><FileUploadLoadingState /></div>);
    }

    if (error) {
        return (<div style={{ marginTop: '30px' }}><FileUploadErrorState text="Select template: Something went wrong!" /></div>);
    }

    return (<Card style={{ marginTop: '30px' }} className="card without-border"
        variant="outlined">
        <div className="file-upload-subitem-wrapper">
            <h3 className="file-upload-subtitle">Select template</h3>

            <Autocomplete
                disablePortal
                style={{ marginTop: '10px' }}
                id="entity"
                sx={{ width: 300 }}
                className="input small-autocomplete"
                size="small"
                options={list}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                disabled={disabled}
                onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                renderInput={(params) => <TextField
                    {...params}
                    InputProps={{  ...params.InputProps, disableUnderline: true }}
                    variant="filled"
                    label="Template type" />}
            />
        </div>
    </Card>);
}

export default SelectTemplate;