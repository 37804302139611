import { FC } from "react";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

import './FileUploadLoadingState.scss';

const FileUploadLoadingState: FC = () => {
    return (<Card style={{
        marginTop: '10px', display: 'flex',
        justifyContent: 'center', alignItems: 'center'
    }} className="card without-border"
        variant="outlined">
        <CircularProgress style={{padding: '20px'}} />
    </Card>);
};

export default FileUploadLoadingState;