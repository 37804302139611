import request, { uploadFileAPI } from "./request";

export function getUploadTemplatesAPI() {
    return request(`/api/Templates`, { method: 'GET' });
}

export function getTemplateFileAPI(templateId: string) {
    return request(`/api/Templates/${templateId}/download`, { method: 'GET' }, undefined, 'download');
}

export function validateFileAPI(templateId: string, file: any) {
    return uploadFileAPI(`/api/Processings/validateFile?templateId=${templateId}`, 'POST', file);
}

export function submitFileAPI(processingId: string) {
    return request(`/api/Processings/${processingId}/submit`, { method: 'POST' });;
}

export function getUploadHistoryAPI(params: any) {
    return request(`/api/Processings?${new URLSearchParams(params)}`, { method: 'GET' });
}

export function archiveHistoryEntryAPI(processingId: any) {
    return request(`/api/Processings/${processingId}/archive`, { method: 'POST' });
}

export function getHistoryFileAPI(processingId: any) {
    return request(`/api/Processings/${processingId}/file`, { method: 'GET' }, undefined, 'download');
}