import { FC, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";

import SelectTemplate from "./SelectTemplate/SelectTemplate";
import DownloadTemplate from "./DownloadTemplate/DownloadTemplate";
import UploadingBlock from "./UploadingBlock/UploadingBlock";
import ValidationFailed from "./ValidationFailed";
import FileSubmited from "./FileSubmited/FileSubmited";
import ValidationSuccessful from "./ValidationSuccessful/ValidationSuccessful";

import './FileUpload.scss';

const steps = ['File Upload', 'Validation', 'File submission'];

const FileUpload: FC = () => {
    const [activeTemplate, setActiveTemplate] = useState<any>(null);
    const [validateFile, setValidateFile] = useState<any>(null);
    const [requestLoading, setRequestLoading] = useState(false);
    const [isFinished, setFinished] = useState(false);

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (validateFile) {
            setActiveStep(1);
        } else {
            setActiveStep(0);
        }
    }, [validateFile]);

    useEffect(() => {
        if(isFinished) {
            setActiveStep(2);
        }
    }, [isFinished]);

    const resetAll = () => {
        setActiveTemplate(null);
        setValidateFile(null);
        setFinished(false);
    };

    return <div className="file-upload-wrapper">
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepButton color="inherit" disabled>
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>

                <>

                    <SelectTemplate value={activeTemplate}
                        disabled={requestLoading || !!validateFile}
                        onChange={(event: any, newValue: any) => {
                            setActiveTemplate(newValue);
                        }}
                    />

                    {!!activeTemplate && (
                        <>
                            <DownloadTemplate {...activeTemplate} />

                            {!validateFile && <UploadingBlock
                                requestLoading={requestLoading}
                                setRequestLoading={setRequestLoading}
                                templateId={activeTemplate?.value}
                                setValidateFile={setValidateFile} />}

                            {!isFinished ? <> {
                                validateFile && <>
                                    {!!validateFile?.validated ?
                                        <ValidationSuccessful resetAll={resetAll} validateFile={validateFile}
                                            requestLoading={requestLoading}
                                            setRequestLoading={setRequestLoading}
                                            finalize={() => setFinished(true)} /> :
                                        <ValidationFailed resetAll={resetAll} validateFile={validateFile} />}
                                </>
                            }
                            </> : <FileSubmited resetAll={resetAll} />}
                        </>)}

                </>

            </div>
        </Box>
    </div>;
}

export default FileUpload;