import { FC } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import ValidationError from "../../../components/ValidationError";

import './ValidationFailed.scss';

type Props = {
    resetAll: Function,
    validateFile: any;
}

const ValidationFailed: FC<Props> = ({ validateFile, resetAll }: Props) => {
    return (<Card style={{ marginTop: '10px' }} className="card without-border"
        variant="outlined">
        <div className="file-upload-subitem-wrapper">
            <h3 className="file-upload-subtitle">Validation failed</h3>
            <div className="file-error-validation-list">
                {validateFile?.errors.map((error:any, i:any) => (
                    (
                        <ValidationError
                            key={i}
                            error={error}
                        />
                    )
                ))}
            </div>
            <Button
                style={{ marginTop: '15px' }}
                onClick={() => {
                    resetAll();
                }}
                disableRipple className="btn-default">
                Upload new file
            </Button>
        </div>

    </Card>);
}

export default ValidationFailed;