export function policiesQueryParams(searchParams: any): Record<string, string> {
    const params: any = {
        pageSize: '20',
        pageNumber: searchParams.get('page') === null ? '1' : String(searchParams.get('page')),
    };

    if (searchParams.get('policyName')) {
        params.policyName = String(searchParams.get('policyName')).trim();
    }

    if (searchParams.get('user')) {
        const [id] =  String(searchParams.get('user')).trim().split('||');
        params.userId = id;
    }

    if (searchParams.get('orderBy')) {
        params.sortingValue = 'Name';
        params.sortingDirection = searchParams.get('orderBy');
    }

    return params;
}

export function uploadHistoryQueryParams(searchParams: any): Record<string, string> {
    const params: any = {
        pageSize: '20',
        pageNumber: searchParams.get('page') === null ? '1' : String(searchParams.get('page')),
    };

    if (searchParams.get('userName')) {
        params.UserName = String(searchParams.get('userName')).trim();
    }

    if (searchParams.get('startDate')) {
        params.FromUtcDateTime = String(searchParams.get('startDate')).trim();
    }

    if (searchParams.get('endDate')) {
        params.ToUtcDateTime = String(searchParams.get('endDate')).trim();
    }

    if (searchParams.get('validated')) {
        params.Validated = String(searchParams.get('validated')).trim();
    }

    if (searchParams.get('submitted')) {
        params.Submitted = String(searchParams.get('submitted')).trim();
    }

    if (searchParams.get('archived')) {
        params.Archived = String(searchParams.get('archived')).trim();
    }

    if (searchParams.get('templateId')) {
        params.TemplateId = String(searchParams.get('templateId')).trim();
    }

    return params;
}

export function logHistoryQueryParams(searchParams: any): Record<string, string> {
    const params: any = {
        pageSize: '20',
        pageNumber: searchParams.get('page') === null ? '1' : String(searchParams.get('page')),
    };

    if (searchParams.get('userName')) {
        params.UserName = String(searchParams.get('userName')).trim();
    }

    if (searchParams.get('startDate')) {
        params.FromUtcDateTime = String(searchParams.get('startDate')).trim();
    }

    if (searchParams.get('endDate')) {
        params.ToUtcDateTime = String(searchParams.get('endDate')).trim();
    }

    if (searchParams.get('eventType')) {
        params.EventId = Number(searchParams.get('eventType'));
    }

    return params;
}

export function templateType(type?: string) {
    return type ? ({
        'DasDataAccessPolicies': 'Data Access Policies',
        'DasDataAccessPoliciesUserAssignment': 'Data Access Policies User Assignment',
        'DasObjectAccessPoliciesUserAssignment': 'Object Access Policies User Assignment'
    })[type] : '';
}

export function downloadFile(blob: any, name: any) {
    const downloadUrl = URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    let filename = name || 'template.xlsx';
    tempLink.style.display = 'none';
    tempLink.href = downloadUrl;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        window.URL.revokeObjectURL(downloadUrl);
    }, 100);
};

export function generateErrorMessageFromResponse(err: any) {
    let message = 'Something went wrong!';
    if (err && err?.errors && err?.errors?.length) {
        message = err?.errors?.reduce(
            (accumulator: any, currentValue: any) => accumulator + currentValue?.message,
            ''
        );
    }
    return message;
}

export function goBackApplications(navigate: Function, applicationId: any) {
    if (window.history.state && window.history.state.idx > 0) {
        navigate(-1);
    } else {
        navigate(`/application/${applicationId}`);
    }
}

export function userLetters(name: string): string {
    const nameArr = name.split(' ').map(n => n[0]);
    return (nameArr[0] || '') + (nameArr[1] || '');
};

function padTwoDigits(num: number) {
    return num.toString().padStart(2, "0");
}  

export function formatDate(date: any) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-') + '_' + ([
        padTwoDigits(date.getHours()),
        padTwoDigits(date.getMinutes()),
        padTwoDigits(date.getSeconds()),
      ].join("-"));
}