import React, { useEffect, useState } from "react";

import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { msalInstance } from "./config/authConfig";

import Root from "./pages/root";
import ManageApplications from "./pages/ManageApplications";
import ErrorPage from "./pages/ErrorPage";
import Unauthorized from "./pages/Unauthorized";
import Application from "./pages/Application";
import AddDataAccessPolicy from "./pages/DataAccessPolicies/AddPolicy";
import EditDataAccessPolicy from "./pages/DataAccessPolicies/EditPolicy";
import AssignDataAccessPolicy from "./pages/DataAccessPolicies/AssignPolicy";
import AssignObjectAccessPolicy from "./pages/ObjectAccessPolicies/AssignPolicy";
import FileUpload from "./pages/FileUpload";
import UploadHistory from "./pages/UploadHistory";
import LogHistory from "./pages/LogHistory";

const RequireAuth = (props: any) => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated && inProgress !== "startup") {
      navigate("/unauthorized");
    } else if (!!localStorage.getItem("chooseApp")) {
      navigate("/select-application");
    }

    if (inProgress !== "startup") {
      setLoading(false);
    }
  }, [isAuthenticated, navigate, inProgress]);

  if (loading) {
    return null;
  }

  return <>{props.children}</>;
};

const cache = createCache({
  key: "css",
  nonce: 'NTYyYjNkNTMtNjgwMC00NDAyLWFjZWYtN2RhM2RhODk3MGQw',
  prepend: true
});

function AppRoutes() {
  return (
    
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CacheProvider value={cache}>
        <CssBaseline />
        <MsalProvider instance={msalInstance}>
          <SnackbarProvider preventDuplicate maxSnack={10}>
            <Routes>
              <Route path="/redirect" element={<Navigate to="/" />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
                <Route errorElement={<ErrorPage />}>
                  <Route
                    index
                    element={<Navigate to="/manage-applications" />}
                  />
                  <Route
                    path="manage-applications"
                    element={
                      <RequireAuth>
                        <ManageApplications />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="application/:id"
                    element={
                      <RequireAuth>
                        <Application />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="application/dap/add-policy/:applicationId"
                    element={
                      <RequireAuth>
                        <AddDataAccessPolicy />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="application/dap/edit-policy/:applicationId/:policyId"
                    element={
                      <RequireAuth>
                        <EditDataAccessPolicy />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="application/dap/assign-policy/:applicationId/:policyId"
                    element={
                      <RequireAuth>
                        <AssignDataAccessPolicy />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="application/oap/assign-policy/:applicationId/:policyId"
                    element={
                      <RequireAuth>
                        <AssignObjectAccessPolicy />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="file-upload"
                    element={
                      <RequireAuth>
                        <FileUpload />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="upload-history"
                    element={
                      <RequireAuth>
                        <UploadHistory />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="log-history"
                    element={
                      <RequireAuth>
                        <LogHistory />
                      </RequireAuth>
                    }
                  />
                </Route>
              </Route>
            </Routes>
          </SnackbarProvider>
        </MsalProvider>
        </CacheProvider>
      </LocalizationProvider>
    
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
