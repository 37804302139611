import { FC } from "react";
import Card from "@mui/material/Card";

import './FileUploadErrorState.scss';

type Props = {
    text: string;
};

const FileUploadErrorState: FC<Props> = ({ text }: Props) => {
    return (<Card style={{
        marginTop: '10px', display: 'flex',
        justifyContent: 'center', alignItems: 'center'
    }} className="card without-border"
        variant="outlined">
        <h1 style={{ padding: '20px' }}>{text}</h1>
    </Card>);
};

export default FileUploadErrorState;