import { FC } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";

import './root.scss';

const Root: FC = () => {
    return (
        <>
            <Header />
            <div className="das-body">
                <Outlet />
            </div>
        </>
    );
};

export default Root;
