import { CircularProgress } from "@mui/material";
import { FC } from "react";

import './TableLoading.scss';

const TableLoading: FC = () => {
    return (<div style={{textAlign: 'center',
    marginTop: '40px',
    marginBottom: '40px'}}>
        <CircularProgress/>
         {/* <Typography component="div" variant="body1">
            <Skeleton />
        </Typography>
        <Typography component="div" variant="h2">
            <Skeleton />
        </Typography>
        <Typography component="div" variant="h3">
            <Skeleton />
        </Typography>
        <Typography component="div" variant="h4">
            <Skeleton />
        </Typography> */}
    </div>);
};

export default TableLoading;