import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { acquireToken } from "../../config/authConfig";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { userLetters } from "../../util/helpers";

import logo from '../../assets/logo.png';
import './Header.scss';


const Header: FC = () => {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [account, setAccount] = useState<any>({});


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (isAuthenticated) {
            acquireToken()
            .then((auth: any) => {
                // getMyProfilePicAPI().then((res: any) => {
                //     setUserPic(URL.createObjectURL(res));
                // }).catch(err => { });

                setAccount(auth?.account);
            });
        }
    }, [isAuthenticated]);

    const signOutAction = () => {
        handleClose();
        instance.logoutPopup();
    };

    return (
        <div className="header">
            <Box sx={{ flexGrow: 1 }}>
                <AppBar style={{ background: '#fff' }} position="static">
                    <Toolbar>
                        <Typography variant="h6" className="logo-block" component="div" sx={{ flexGrow: 1 }}>
                            <img onClick={() => navigate('/')} style={{ cursor: 'pointer' }} src={logo} alt="Majorel" />
                            <h3>|</h3><h3 style={{ marginLeft: '10px' }}>Data Access & Security Service</h3>
                            {isAuthenticated && <>
                                <Link onClick={() => navigate('/file-upload')} style={{ cursor: 'pointer', marginLeft: '10px', fontSize: '14px' }}>File upload</Link>
                                <Link onClick={() => navigate('/upload-history')} style={{ cursor: 'pointer', marginLeft: '10px', fontSize: '14px' }}>Upload history</Link>
                                <Link onClick={() => navigate('/log-history')} style={{ cursor: 'pointer', marginLeft: '10px', fontSize: '14px' }}>Log history</Link>
                            </>}
                        </Typography>

                        {isAuthenticated ? <>
                            <Typography className="user-info" style={{ color: '#000', cursor: 'pointer' }}
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}

                                variant="h6" component="div">
                                <div className="user-round">
                                    {userLetters(account?.name|| '')}
                                </div>
                                <p style={{ fontSize: '17px' }}>{account?.name}</p>
                            </Typography>


                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={signOutAction}>Sign Out</MenuItem>
                            </Menu>
                        </> : (
                            <Typography className="user-info"
                                variant="h6" component="div">
                                <Button onClick={() => {
                                    instance.loginPopup();
                                }}>Sign in</Button>
                            </Typography>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
        </div>

    );
};

export default Header;
