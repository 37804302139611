import { FC, useState, useMemo, useEffect } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { Link } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import UserSelect from "./UserSelect";
import { UserFilterDTO } from "../../models/UserFilterDTO";

import './TableFilterPolicy.scss';


type Props = {
    inputPolicyLabel: string;
    inputPolicyName: string;
    showUserFilter?: boolean;
};

const TableFilterPolicy: FC<Props> = ({ inputPolicyLabel, inputPolicyName, showUserFilter = false }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [policyValue, setPolicyValue] = useState('');
    const [userValue, setUserValue] = useState<UserFilterDTO | null>(null);


    const queryParams = useMemo(() => searchParams.toString(), [searchParams]);

    useEffect(() => {
        const str = searchParams.get('policyName') !== null ? String(searchParams.get('policyName')) : '';
        setPolicyValue(str);
        const user = searchParams.get('user') !== null ? String(searchParams.get('user')) : '';
        if (user.length) {
            const [id, email] = user.split('||');
            setUserValue({ id: Number(id), email });
        }
    }, [searchParams, queryParams]);

    return (<div className="table-filter-policy">
        <TextField
            sx={{ width: 200 }}
            className="input small"
            variant="filled"
            name={inputPolicyName}
            value={policyValue}
            onChange={e => setPolicyValue(e.target.value)}
            size="small"
            InputProps={{ disableUnderline: true }}
            label={inputPolicyLabel} />

        {showUserFilter && <UserSelect value={userValue} setValue={setUserValue} />}

        <Button style={{ width: '120px' }}
            onClick={() => {
                if (policyValue || userValue) {
                    const params: any = {};
                    if (policyValue) {
                        params.policyName = policyValue;
                    }

                    if (userValue) {
                        params.user = `${userValue.id}||${userValue.email}`;
                    }
                    setSearchParams(createSearchParams(params));
                } else {
                    setSearchParams(createSearchParams());
                }
            }}
            disableRipple className="btn-default">
            Apply Filter
        </Button>

        <Link
            className="link-default"
            onClick={() => {
                setSearchParams(createSearchParams());
                setPolicyValue('');
                setUserValue(null);
            }}
        >
            Reset filtering
        </Link>

    </div>);
};

export default TableFilterPolicy;