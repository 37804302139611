import request from "./request";

export function getCurrentApplicationAPI(id: string) {
    return request(`/api/applications/${id}`, { method: 'GET' });
}

export function getCurrentApplicationDataAccessPoliciesAPI(id: string, params: Record<string, string>) {
    return request(`/api/applications/${id}/data-access-policies?${new URLSearchParams(params)}`, { method: 'GET' });
}

export function getCurrentApplicationObjectAccessPoliciesAPI(id: string, params: Record<string, string>) {
    return request(`/api/applications/${id}/object-access-policies?${new URLSearchParams(params)}`, { method: 'GET' });
}

export function getCurrentApplicationDataAccessRolesAPI(id: string) {
    return request(`/api/applications/${id}/roles`, { method: 'GET' });
}

