import { FC, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { LoadingButton } from "@mui/lab";
import { generateErrorMessageFromResponse } from "../../../util/helpers";
import { submitFileAPI } from "../../../api/fileUploadAPI";

import './ValidationSuccessful.scss';


type Props = {
    resetAll: Function;
    validateFile: any;
    requestLoading: boolean;
    setRequestLoading: Function;
    finalize: Function;
}

const ValidationSuccessful: FC<Props> = ({ resetAll, validateFile, requestLoading, setRequestLoading, finalize }: Props) => {
    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState<any>({});
    useEffect(() => {
        try {
            setData(JSON.parse(validateFile?.statisticsInJson));
        } catch (e) { }
    }, [validateFile]);
    return (<Card style={{ marginTop: '10px' }} className="card without-border"
        variant="outlined">
        <div className="file-upload-subitem-wrapper">
            <h3 className="file-upload-subtitle">Validation is successful</h3>

            <TableContainer>
                <Table sx={{ minWidth: 400 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Number of new items</TableCell>
                            <TableCell align="right">Number of updated items</TableCell>
                            <TableCell align="right">Number of deleted items</TableCell>
                            <TableCell align="right">Total amount of items</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {data?.createdRowsNumber}
                            </TableCell>
                            <TableCell align="right">
                                {data?.updatedRowsNumber}
                            </TableCell>
                            <TableCell align="right">
                                {data?.softDeletedRowsNumber}
                            </TableCell>
                            <TableCell align="right">
                                {data?.rowsTotalAmount}
                            </TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <div className="validation-successful-btns">
                <Button
                    disabled={requestLoading}
                    style={{ marginTop: '15px' }}
                    onClick={() => {
                        resetAll();
                    }}

                    disableRipple className="btn-default">
                    Upload new file
                </Button>

                <LoadingButton style={{ marginTop: '15px' }} loading={requestLoading}
                    onClick={() => {
                        setRequestLoading(true);

                        submitFileAPI(validateFile?.proccessingId)
                            .then(() => { setRequestLoading(false); finalize(); })
                            .catch((err) => {
                                enqueueSnackbar(generateErrorMessageFromResponse(err), {
                                    variant: "error",
                                    autoHideDuration: 3000,
                                    anchorOrigin: { vertical: "top", horizontal: "center" }
                                });
                            })
                            .finally(() => setRequestLoading(false));
                    }}

                    disableRipple className="btn-default">
                    Submit file
                </LoadingButton>

            </div>

        </div>

    </Card>);
}

export default ValidationSuccessful;
