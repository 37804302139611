import { FC, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import ErrorNotifications from "../../../components/ErrorNotifications";

import { addDapPolicyAPI, getEntitiesAttributesAPI } from "../../../api/policiesAPI";
import { goBackApplications } from "../../../util/helpers";

import './AddPolicy.scss';

const AddPolicy: FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    let { applicationId } = useParams();

    const [entitiesAttributes, setEntitiesAttributes] = useState<any>([]);
    const [loadingEntitiesAttributes, setLoadingEntitiesAttributes] = useState(false);
    const [errorEntitiesAttributes, setErrorEntitiesAttributes] = useState<any>();

    const [currentEntityId, setCurrentEntityId] = useState<any>();
    const [currentAttributeId, setCurrentAttributeId] = useState<any>();
    const [currentAttributeValueId, setCurrentAttributeValueId] = useState<any>();

    const [addList, setAddList] = useState<any>([]);
    const [policyName, setPolicyName] = useState('');
    const [showTheSameUserError, setShowTheSameUserError] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const [addError, setAddError] = useState<any>();

    useEffect(() => {
        if (applicationId) {
            setLoadingEntitiesAttributes(true);
            getEntitiesAttributesAPI(applicationId)
                .then((res) => {
                    setEntitiesAttributes(
                        res?.entities?.map((entity: any) => ({
                            value: entity?.id,
                            label: entity?.name,
                            attributes: entity?.attributes.map((attribute: any) => ({
                                value: attribute?.id,
                                label: attribute?.name,
                                attributeValues: attribute.attributeValues.map((attributeValue: any) => ({
                                    value: attributeValue?.id,
                                    label: attributeValue?.name,
                                }))
                            }))
                        }))
                    );
                })
                .catch(err => {
                    setErrorEntitiesAttributes(err);
                })
                .finally(() => {
                    setLoadingEntitiesAttributes(false);
                });
        }
    }, [applicationId]);

    const entityMemoizedValue = useMemo(() => {
        return entitiesAttributes?.find((c: any) => c.value === currentEntityId)?.label;
    }, [entitiesAttributes, currentEntityId]);

    const attributeMemoizedValue = useMemo(() => {
        const entity = entitiesAttributes?.find((c: any) => c.value === currentEntityId);
        if (!entity) return null;
        return entity?.attributes.find((a: any) => a?.value === currentAttributeId);
    }, [entitiesAttributes, currentEntityId, currentAttributeId]);

    const attributeValueMemoizedValue = useMemo(() => {
        const entity = entitiesAttributes?.find((c: any) => c.value === currentEntityId);
        if (!entity) return null;
        const attribute = entity?.attributes.find((a: any) => a?.value === currentAttributeId);
        if (!attribute) return null;
        return attribute.attributeValues.find((a: any) => a?.value === currentAttributeValueId);

    }, [entitiesAttributes, currentEntityId, currentAttributeId, currentAttributeValueId]);


    const memoizedAttributes = useMemo(() => {
        const entity = entitiesAttributes?.find((c: any) => c.value === currentEntityId);
        if (!entity) return [];
        return entity?.attributes;
    }, [entitiesAttributes, currentEntityId]);

    const memoizedAttributeValues = useMemo(() => {
        const entity = entitiesAttributes?.find((c: any) => c.value === currentEntityId);
        if (!entity) return [];
        const attribute = entity?.attributes.find((a: any) => a?.value === currentAttributeId);
        if (!attribute) return null;
        return attribute?.attributeValues;
    }, [entitiesAttributes, currentEntityId, currentAttributeId]);

    if (loadingEntitiesAttributes) {
        return (
            <div className="add-policy-wrapper" style={{ textAlign: 'center' }}>
                <CircularProgress />
            </div>
        );
    }

    if (errorEntitiesAttributes) {
        return <div className="assign-policy-wrapper">
            <Typography component="div" style={{
                textAlign: 'center',
                marginTop: '40px',
                marginBottom: '40px'
            }} variant="h6">
                Something went wrong!
            </Typography>
        </div>
    }

    return (
        <div className="add-policy-wrapper">
            <div className="back-construction" style={{ marginBottom: '10px' }}>
                <ChevronLeftIcon onClick={() => {
                    goBackApplications(navigate, applicationId);
                }} className="icon-back" /><h2 className="app-header-title">
                    Create New Policy
                </h2>
            </div>

            <Card className="card without-border"
                variant="outlined">

                <div className="current-app-data">
                    <TextField
                        style={{ maxWidth: '300px' }}
                        className="input"
                        size="small"
                        required
                        value={policyName}
                        onChange={(e) => setPolicyName(e.target.value)}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        label="Policy Name" />
                </div>

                <div className="current-app-data" style={{ flexDirection: 'row', paddingTop: '0' }}>
                    <Autocomplete
                        disablePortal
                        id="entity"
                        sx={{ width: 200 }}
                        className="input"
                        size="small"
                        options={entitiesAttributes}
                        value={entityMemoizedValue || null}
                        onChange={(event: any, newValue: any) => {
                            setCurrentEntityId(newValue?.value);
                            setCurrentAttributeId(null);
                        }}
                        renderInput={(params) => <TextField
                            variant="filled"
                            {...params}
                            InputProps={{ disableUnderline: true, ...params.InputProps }}
                            label="Entity" />}
                    />

                    <Autocomplete
                        disablePortal
                        id="attribute"
                        sx={{ width: 200 }}
                        className="input"
                        size="small"
                        disabled={!currentEntityId}
                        options={memoizedAttributes}
                        value={attributeMemoizedValue || null}
                        onChange={(event: any, newValue: any) => {
                            setCurrentAttributeId(newValue?.value);
                            setCurrentAttributeValueId(null);
                        }}
                        renderInput={(params) => <TextField
                            variant="filled"
                            {...params}
                            InputProps={{ disableUnderline: true, ...params.InputProps }}
                            label="Attribute" />}
                    />

                    <Autocomplete
                        disablePortal
                        id="attribute"
                        sx={{ width: 200 }}
                        className="input"
                        size="small"
                        disabled={!currentAttributeId}
                        options={memoizedAttributeValues}
                        value={attributeValueMemoizedValue || null}
                        onChange={(event: any, newValue: any) => {
                            setCurrentAttributeValueId(newValue?.value);
                        }}
                        renderInput={(params) => <TextField
                            variant="filled"
                            {...params}
                            InputProps={{ disableUnderline: true, ...params.InputProps }}
                            label="Attribute Value" />}
                    />

                    <Button style={{ width: '120px' }}
                        disabled={!currentEntityId || !currentAttributeId || !currentAttributeValueId || saveLoading}
                        onClick={() => {
                            if (addList.find((filter: any) => String(filter?.entityId) + String(filter?.attributeId)
                                === String(currentEntityId) + String(currentAttributeId))) {
                                setShowTheSameUserError(true);

                            } else {
                                setAddList((oldAddList: any) => ([...oldAddList, {
                                    attributeValueId: currentAttributeValueId,
                                    entityId: currentEntityId,
                                    attributeId: currentAttributeId,
                                }]));

                                setCurrentEntityId(null);
                                setCurrentAttributeId(null);
                                setCurrentAttributeValueId(null);
                            }
                        }}
                        disableRipple className="btn-default">
                        Add
                    </Button>
                </div>

                <div className="current-app-data" style={{ flexDirection: 'row', paddingTop: '0' }}>

                    <TableContainer>
                        <Table sx={{ minWidth: 400 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Filter Entity</TableCell>
                                    <TableCell align="right">Filter Attribute</TableCell>
                                    <TableCell align="right">Filter Value</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {addList?.map((element: any) => (<TableRow key={String(element?.entityId) + String(element?.attributeId)
                                    + String(element?.attributeValueId)}>
                                    <TableCell>
                                        {entitiesAttributes?.find((c: any) => c.value === element?.entityId)?.label}
                                    </TableCell>
                                    <TableCell align="right">
                                        {entitiesAttributes?.find((c: any) => c.value === element?.entityId)?.attributes.find((a: any) => a?.value === element?.attributeId)?.label}
                                    </TableCell>
                                    <TableCell align="right">
                                        {entitiesAttributes?.find((c: any) => c.value === element?.entityId)?.attributes
                                            .find((a: any) => a?.value === element?.attributeId)?.attributeValues
                                            .find((a: any) => a?.value === element?.attributeValueId)?.label}
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton disabled={saveLoading} onClick={() => {
                                            setAddList((oldAddList: any) => oldAddList.filter((el: any) => String(el?.entityId) + String(el?.attributeId)
                                                + String(el?.attributeValueId) !== String(element?.entityId) + String(element?.attributeId) +
                                                String(element?.attributeValueId)));
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>

                                    </TableCell>

                                </TableRow>))}
                            </TableBody>
                        </Table>

                        {addList?.length === 0 && <Typography component="div" style={{
                            textAlign: 'center',
                            marginTop: '40px',
                            marginBottom: '40px'
                        }} variant="h6">
                            No Data!
                        </Typography>}

                    </TableContainer>
                </div>

                <div className="current-app-data" style={{ flexDirection: 'row', justifyContent: 'flex-end', paddingTop: '0' }}>

                    <LoadingButton loading={saveLoading} disabled={!policyName?.trim() || !addList.length || saveLoading}
                        onClick={() => {
                            setSaveLoading(true);
                            addDapPolicyAPI({
                                name: policyName,
                                applicationId,
                                filters: addList
                            })
                                .then(() => {
                                    enqueueSnackbar('Policy Created Successfully!', {
                                        variant: "success",
                                        autoHideDuration: 3000,
                                        anchorOrigin: { vertical: "top", horizontal: "center" }
                                    });

                                    navigate(`/application/${applicationId}`);
                                }).catch(err => {
                                    setAddError(err);
                                })
                                .finally(() => {
                                    setSaveLoading(false);
                                })
                        }}
                        style={{ width: '120px', height: '52px' }} disableRipple className="btn-default">
                        Save
                    </LoadingButton>
                </div>
            </Card>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={showTheSameUserError}
                autoHideDuration={3000}
                onClose={() => setShowTheSameUserError(false)}
                key={0}
            >
                <Alert elevation={6} variant="filled" onClose={() => setShowTheSameUserError(false)} severity="error" sx={{ width: '100%' }}>
                    The filter is already added!
                </Alert>
            </Snackbar>

            <ErrorNotifications error={addError} />
        </div>
    );
};

export default AddPolicy;