import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import TableLoading from "../../components/TableLoading";
import { getManageApplicationsAPI } from "../../api/manageApplicationsAPI";

import './ManageApplications.scss';

const ManageApplications: FC = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>();
    const [data, setData] = useState<any>();

    useEffect(() => {
        getManageApplicationsAPI().then((res: any) => {
            setData(res);
        })
            .catch(err => { setError(err); })
            .finally(() => { setLoading(false) });
    }, []);

    return <div className="manage-applications-container">
        <TableContainer>
            <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>App Code</TableCell>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right">App Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {data && data.map((item: any) => (<TableRow
                        key={item?.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <Link onClick={() => {
                                navigate(`/application/${item?.id}`);
                            }} component="button" underline="none">
                                {item?.code}
                            </Link>


                        </TableCell>

                        <TableCell align="right">{item?.type}</TableCell>
                        <TableCell align="right">{item?.name}</TableCell>

                    </TableRow>))}
                </TableBody>
            </Table>
            {loading && <TableLoading />}
            {error && <Typography component="div" style={{
                textAlign: 'center',
                marginTop: '40px',
                marginBottom: '40px'
            }} variant="h6">
                Something went wrong!
            </Typography>}

            {data && data?.length === 0 && <Typography component="div" style={{
                textAlign: 'center',
                marginTop: '40px',
                marginBottom: '40px'
            }} variant="h6">
                No Data!
            </Typography>}


        </TableContainer>
    </div>
}

export default ManageApplications;