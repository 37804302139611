import { Card, CircularProgress } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCurrentApplicationAPI } from "../../api/applicationsAPI";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import './Application.scss';
import DataAccessPolicies from "./DataAccessPolicies";
import ObjectAccessPolicies from "./ObjectAccessPolicies";
// import DataAccessRoles from "./DataAccessRoles";

const Application: FC = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [currentApplication, setCurrentApplication] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>();

    useEffect(() => {
        if (id) {
            getCurrentApplicationAPI(id).then((res: any) => {
                setCurrentApplication(res);
            })
                .catch(err => { setError(err); })
                .finally(() => { setLoading(false) });
        }
    }, [id]);

    return (<div className="current-application-wrapper">
        {currentApplication && <div className="back-construction" style={{ marginBottom: '10px' }}>
            <ChevronLeftIcon onClick={() => {
                navigate('/manage-applications');
            }} className="icon-back" /><h2 className="app-header-title">
                Application Service {currentApplication?.code}
            </h2>
        </div>}
        <Card className="card without-border"
            variant="outlined">
            {currentApplication && <div className="current-app-data">
                <h2 className="app-text">Application Service Code: {currentApplication?.code}</h2>
                <h2 className="app-text">Application Service Name: {currentApplication?.name}</h2>
                <h2 className="app-text">Type: {currentApplication?.type}</h2>
            </div>}

            {
                loading && (
                    <div className="current-app-data" style={{ alignItems: 'center' }}>
                        <CircularProgress />
                    </div>
                )
            }

            {
                error && (
                    <div className="current-app-data" style={{ alignItems: 'center' }}>
                        <h2 className="app-text">Something went wrong!</h2>
                    </div>
                )
            }
        </Card>

        {currentApplication?.policyType === 'DAP' && <DataAccessPolicies />}
        {/* <DataAccessRoles /> */}
        {currentApplication?.policyType === 'OAP' && <ObjectAccessPolicies />}
    </div>);
};

export default Application;