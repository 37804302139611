import { FC, useEffect, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ValidationError from "../../../components/ValidationError";

import { templateType } from "../../../util/helpers";

type Props = {
    onClose: Function;
    report?: any;
    reportData?: any;
}

const ReportModal: FC<Props> = ({ onClose, report }) => {
    const [templateName, setTemplateName] = useState<any>();
    const [successData, setSuccessData] = useState<any>();
    const [errors, setErrors] = useState<any>([]);

    useEffect(() => {
        if (report) {
            setTemplateName(templateType(report?.templateType));

            let data = {};
            try {
                data = JSON.parse(report?.statisticsInJson);
            } catch (e) { }
            setSuccessData(data);
            setErrors(report?.errors);
        }
    }, [report]);

    const data = (
        <TableContainer>
            <Table sx={{ minWidth: 400 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Number of new items</TableCell>
                        <TableCell align="right">Number of updated items</TableCell>
                        <TableCell align="right">Number of deleted items</TableCell>
                        <TableCell align="right">Total amount of items</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            {successData?.createdRowsNumber}
                        </TableCell>
                        <TableCell align="right">
                            {successData?.updatedRowsNumber}
                        </TableCell>
                        <TableCell align="right">
                            {successData?.softDeletedRowsNumber}
                        </TableCell>
                        <TableCell align="right">
                            {successData?.rowsTotalAmount}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );

    const error = (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', gap: '10px', maxHeight: '300px' }}>

            {report?.errors.map((error: any, i: any) => (

                <ValidationError
                    key={i}
                    error={error}
                />

            ))}

        </div>
    );

    return (
        <Dialog
            PaperProps={{
                style: {
                    borderRadius: '24px',
                    padding: '30px',
                    gap: '14px',
                }
            }}
            open={!!report}>
            <DialogTitle className="lato center bold">Validation report for {templateName}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="lato center">
                    {
                        errors.length ? <>{error}</> : <>{data}</>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-end' }}>
                <LoadingButton style={{ width: '120px' }}
                    disableRipple className="btn-default"
                    onClick={() => {
                        onClose();
                    }}>Close</LoadingButton>
            </DialogActions>
        </Dialog>
    )
};

export default ReportModal;