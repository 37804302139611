import request from "./request";

export function getDapAPI(policyId: any) {
    return request(`/api/data-access-policies/${policyId}/filters`, { method: 'GET' });
}

export function editDapPolicyAPI(policyId: any, body: any) {
    return request(`/api/data-access-policies/${policyId}`, { method: 'PUT' }, body);
}

export function deleteDapPolicyAPI(policyId: any) {
    return request(`/api/data-access-policies/${policyId}`, { method: 'DELETE' });
}

export function getDapAssignedUsersAPI(policyId: any) {
    return request(`/api/data-access-policies/${policyId}/users`, { method: 'GET' });
}

export function saveDapAssignedUsersAPI(policyId: any, body: any) {
    return request(`/api/data-access-policies/${policyId}/users`, { method: 'PUT' }, body);
}

export function addDapPolicyAPI(body: any) {
    return request(`/api/data-access-policies`, { method: 'POST' }, body);
}

export function getEntitiesAttributesAPI(applicationId: any) {
    return request(`/api/applications/${applicationId}/entities`, { method: 'GET' });
}

// OAP

export function deleteOapPolicyAPI(policyId: any) {
    return request(`/api/object-access-policies/${policyId}`, { method: 'DELETE' });
}

export function getOapAssignedUsersAPI(policyId: any) {
    return request(`/api/object-access-policies/${policyId}/users`, { method: 'GET' });
}

export function saveOapAssignedUsersAPI(policyId: any, body: any) {
    return request(`/api/object-access-policies/${policyId}/users`, { method: 'PUT' }, body);
}

export function addOapPolicyAPI(body: any) {
    return request(`/api/object-access-policies`, { method: 'POST' }, body);
}

export function exportDapFileAPI(body: any) {
    return request(`/api/data-access-policies/export`, { method: 'POST' }, body, 'download');
}
