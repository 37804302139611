import { FC, Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import dayjs from "dayjs";

import LogHistoryFiltering from "./LogHistoryFiltering/LogHistoryFiltering";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";

import PaginationPolicy from "../../components/PaginationPolicy";
import TableLoading from "../../components/TableLoading";

import { logHistoryQueryParams } from "../../util/helpers";

import { getLogHistoryAPI } from "../../api/logHistoryAPI";

import './LogHistory.scss';

const LogHistory: FC = () => {

    const [showPagingDuringLoading, setShowPagingDuringLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>();
    const [searchParams] = useSearchParams();

    const fetchData = (searchParams: any) => {
        setData(undefined);
        setLoading(true);
        getLogHistoryAPI(logHistoryQueryParams(searchParams)).then((res: any) => {
            setData(res?.entity);
            setTotalPages(res?.totalPages);
        })
            .catch((err: any) => { setError(err); })
            .finally(() => {
                setLoading(false);
                setShowPagingDuringLoading(false);
            });
    };

    useEffect(() => {
        fetchData(searchParams);
    }, [searchParams]);

    return (<div className="log-history-wrapper">
        <LogHistoryFiltering />

        <TableContainer style={{ marginTop: '30px' }}>
            <Table sx={{ minWidth: 400 }} size="small">
                <TableHead>
                    <TableRow>
           
                        <TableCell>Application name</TableCell>
                        <TableCell align="left">Event</TableCell>
                        <TableCell align="left">Date Occured</TableCell>
                        <TableCell align="left">Account</TableCell>
                        <TableCell align="left">Policy Name</TableCell>
                        <TableCell align="left">Initiated by</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.map((item: any) => (<Fragment key={item?.id}>
                        <TableRow>
                            <TableCell>{item?.applicationName}</TableCell>
                            <TableCell align="left">{item?.event}</TableCell>
                            <TableCell align="left">{dayjs(item?.date).format('ddd MMMM D HH:mm:ss CET YYYY')}</TableCell>
                            <TableCell align="left">{item?.email}</TableCell>
                            <TableCell align="left">{item?.policies?.join(', ')}</TableCell>
                            <TableCell align="left">{item?.initiator}</TableCell>

                        </TableRow>
                    </Fragment>))}
                </TableBody>
            </Table>

            {loading && <TableLoading />}
            {error && <Typography component="div" style={{
                textAlign: 'center',
                marginTop: '40px',
                marginBottom: '40px'
            }} variant="h6">
                Something went wrong!
            </Typography>}

            {data && data?.length === 0 && <Typography component="div" style={{
                textAlign: 'center',
                marginTop: '40px',
                marginBottom: '40px'
            }} variant="h6">
                Log history is empty!
            </Typography>}
        </TableContainer>

        {(!error && (showPagingDuringLoading || (!!totalPages && !loading))) && <PaginationPolicy totalPages={totalPages} onChange={() => setShowPagingDuringLoading(true)} />}
    </div>);
}

export default LogHistory;